import React from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { FilePlus, Pen, Trash } from 'lucide-react'

import useWorkspaceAPITokens from './workspace-api-tokens-list.hook'

export default function WorkspaceApiTokensListPage() {

  const { results, count } = useWorkspaceAPITokens()
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'))

  return (
    <Stack alignItems={'flex-start'}>
      <Typography
        variant={'h4'}>
        API Tokens
      </Typography>
      <Typography color={'textSecondary'}>
        Number of API tokens found: {count}
      </Typography>

      <Button
        component={Link}
        href={'create'}
        startIcon={<FilePlus />}
        variant={'outlined'}
        sx={{
          marginY: 2
        }}
      >
        Create new API Token
      </Button>
      <Stack pt={2}>
        <TableContainer>
          <Table
            aria-label={'simple table'}
            stickyHeader>
            <TableHead>
              <TableRow
                sx={{
                  '& th': {
                    borderWidth: '2px',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    minWidth: '100px',
                    maxWidth: '250px'
                  } }}>
                <TableCell >Title</TableCell>
                {isLargeScreen && <TableCell >Description</TableCell> }
                <TableCell >Allowed Origins</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((item) => (
                <TableRow
                  key={item.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component={'th'}
                    scope={'row'}>
                    {item.title}
                  </TableCell>
                  {isLargeScreen && <TableCell>{item.description}</TableCell>}
                  <TableCell>
                    {item.allowed_origins.join(', ')}
                  </TableCell>
                  <TableCell>
                    <Stack
                      direction={'row'}
                      gap={2}>
                      <IconButton
                        component={Link}
                        href={item.id}
                        size={'small'}>
                        <Pen size={18} />
                      </IconButton>
                      <IconButton
                        size={'small'}>
                        <Trash size={18} />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  )
}
