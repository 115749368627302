import React from 'react'
import { FieldError } from 'react-hook-form/dist/types/errors'
import FormControl from '@mui/material/FormControl'
import { InputProps } from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import TextField, { TextFieldProps } from '@mui/material/TextField'

import isEmpty from 'utils/isEmpty'
import omit from 'utils/omit'

import { FormFieldType } from './form-field.type'
import FormFieldHelp from './form-field-help.components'

interface FormTextFieldProps extends FormFieldType, Pick<TextFieldProps, 'variant'>, InputProps {
  errors?: FieldError
}

function FormTextField({
  startAdornment, endAdornment, ...inputRest
}: FormTextFieldProps, ref: React.ForwardedRef<HTMLInputElement>) {

  const hasErrors = !isEmpty(inputRest.errors)
  return (
    <FormControl
      error={hasErrors}>
      <TextField
        ref={ref}
        error={hasErrors}
        slotProps={{
          input: {
            startAdornment: startAdornment && (
              <InputAdornment position={'start'}>
                {startAdornment}
              </InputAdornment>
            ),
            endAdornment: endAdornment && (
              <InputAdornment position={'end'}>
                {endAdornment}
              </InputAdornment>
            )
          }
        }}
        {...omit(inputRest, ['watch'])}
      />
      <FormFieldHelp {...inputRest} />
    </FormControl>
  )
}

export default React.forwardRef(FormTextField)
