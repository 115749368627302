const config = {

  maxWidth: parseInt(process.env.REACT_APP_IMAGE_MAX_WIDTH || '0'),
  maxHeight: parseInt(process.env.REACT_APP_IMAGE_MAX_HEIGHT || '0'),

  quality: parseInt(process.env.REACT_APP_IMAGE_QUALITY || '0'),
  compressFormat: process.env.REACT_APP_IMAGE_COMPRESS_FORMAT,
  outputType: process.env.REACT_APP_IMAGE_OUTPUT_TYPE
}

export default config
