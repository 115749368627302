import * as React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import GlobalStyles from '@mui/material/GlobalStyles'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Menu } from 'lucide-react'
import { useRecoilState } from 'recoil'

import Logo from 'components/logo'
import useMe from 'modules/state/me.hook'
import { sideMenuAtom } from 'modules/state/side-menu.atom'

import HeaderNoAuth from './header-no-auth'

export default function Header() {
  const { isLoggedIn } = useMe()

  const toggleSideMenu = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const [sideMenu, setSideMenu] = useRecoilState(sideMenuAtom)

  React.useEffect(() => {
    if ((!isLoggedIn || toggleSideMenu) && sideMenu.isOpen) {
      toggleSideMenuVisibility()
    }

  }, [isLoggedIn, toggleSideMenu, sideMenu.isOpen])

  const toggleSideMenuVisibility = React.useCallback(() => {
    setSideMenu((prevState) => ({ ...prevState, isOpen: !prevState?.isOpen }) )
  }, [])

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        zIndex: 1299,
        width: '100%',
        backgroundColor: 'background.default'
      }}>
      <Container
        maxWidth={isLoggedIn ? false : 'lg'}
        sx={{
          height: 'var(--Header-height, 0)',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <GlobalStyles
          styles={{
            ':root': {
              '--Header-height': '52px'
            }
          }}
        />
        <Stack
          alignItems={'center'}
          direction={'row'}>
          {!toggleSideMenu && (
            <IconButton
              onClick={toggleSideMenuVisibility}
              sx={{
                mr: 1
              }}>
              <Menu />
            </IconButton>
          )}
          <Link href={'/'}>
            <Logo />
          </Link>
        </Stack>
        <HeaderNoAuth isLoggedIn={isLoggedIn}/>
      </Container>
    </Box>
  )
}
