import isArray from './isArray'
import isObject from './isObject'

function omit(obj: Record<string, unknown> | undefined, keys: Array<string>): Record<string, unknown> | undefined {

  if (!obj) {
    return obj
  }

  if (!isArray(keys) || !isObject(obj)) {
    return {}
  }

  if (!keys?.length) {
    return obj
  }

  const { [keys.pop() as string]: omitted, ...rest } = obj

  return omit(rest, keys)
}

export default omit
