import React from 'react'
import { useForm } from 'react-hook-form'

import { notify } from 'components/snackbar'
import Rest from 'modules/rest'
import useMe from 'modules/state/me.hook'
import { User } from 'types/user'

type UserInputs = {
  name: string
  email: string
}

export function useProfileOverview() {
  const [loading, setLoading] = React.useState(false)
  const { id, invalidate } = useMe(false)
  const me = useMe()

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm<UserInputs>({
    defaultValues: {
      name: me.name,
      email: me.email
    }
  })

  function login(data: UserInputs) {
    if (!id) {
      return
    }
    setLoading(true)
    Rest.users.patch(id, data).then(async (response: User) => {
      await invalidate(response)
      notify('Updated', 'success')
    }).catch((error) => {
      notify(error.message, 'error')
    }).finally(() => {
      setLoading(false)
    })
  }

  return {
    register,
    handleSubmit: handleSubmit(login),
    formState: { errors, isDirty },
    loading
  }
}
