function sort(fieldName: string) {
  return (a: Record<string, any>, b :Record<string, any>) => {
    const nameA = a[fieldName]
    const nameB = b[fieldName]
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    // names must be equal
    return 0
  }
}

export default sort
