import React from 'react'
import List from '@mui/material/List'
import { LifeBuoy, LogOut, User } from 'lucide-react'

import useMe from 'modules/state/me.hook'

import SideMenuDrawerItem from './side-menu-drawer-item'

function SideMenuDrawerUser() {

  const me = useMe(false)

  return (
    <List dense>
      <SideMenuDrawerItem
        href={'/support/help'}
        icon={<LifeBuoy />}
        text={'Support'}/>
      <SideMenuDrawerItem
        href={'/profile'}
        icon={<User />}
        text={me.name || me.email || 'Profile'}
        textSecondary={me.name ? me.email : undefined}
        disableSelect
      />
      <SideMenuDrawerItem
        action={me.logout}
        icon={<LogOut />}
        text={'Logout'} />
    </List>
  )
}

export default SideMenuDrawerUser
