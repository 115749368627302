import React from 'react'
import { FieldError } from 'react-hook-form/dist/types/errors'
import Checkbox ,{ CheckboxProps } from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'

import isEmpty from 'utils/isEmpty'
import omit from 'utils/omit'

import { FormFieldType } from './form-field.type'
import FormFieldHelp from './form-field-help.components'

interface FormCheckboxFieldProps extends FormFieldType, CheckboxProps {
  errors?: FieldError
}

function FormCheckboxField({ label, ...checkboxRest }: FormCheckboxFieldProps, ref: React.ForwardedRef<HTMLInputElement>) {

  const hasErrors = !isEmpty(checkboxRest.errors)

  return (
    <FormControl
      error={hasErrors}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}>
        <Checkbox
          color={'primary'}
          inputRef={ref}
          {...omit(checkboxRest, ['watch'])}
          checked={checkboxRest.value as boolean}
          size={'large'}
        />

        <Typography
          color={'textPrimary'}
          variant={'body2'}>
          {label}
        </Typography>
      </div>
      <FormFieldHelp {...checkboxRest} />
    </FormControl>
  )
}

export default React.forwardRef(FormCheckboxField)
