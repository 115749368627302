import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import Logo from 'components/logo'

import { FormTextField } from '../form/form-field'
type RequestNewsletterInput = {
    email: string
}
export default function FooterNewsletter() {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RequestNewsletterInput>()

  const handleNewsletter = React.useCallback((data: RequestNewsletterInput) => {
    setLoading(true)
  }, [])

  return (
    <Stack
      width={{
        xs: '100%',
        sm: '60%'
      }}>

      <form
        onSubmit={handleSubmit(handleNewsletter) }
        noValidate>
        <Stack
          gap={3}
          alignItems={{
            xs: 'center',
            sm: 'flex-start'
          }}>
          <Logo size={'small'} />

          <Stack
            alignItems={{
              xs: 'center',
              sm: 'flex-start'
            }}>
            <Typography
              color={'textPrimary'}
              variant={'body1'}
            >
            Keep up to date
            </Typography>

            <Typography
              color={'textSecondary'}
              variant={'body2'}
            >
            Join our newsletter for regular updates. No spam ever.
            </Typography>

            <Stack
              alignItems={'flex-start'}
              direction={'row'}
              gap={1}
              py={2}>
              <FormTextField
                field={errors.email}
                label={t('Email')}
                placeholder={'example@email.com'}
                size={'small'}
                type={'email'}
                variant={'outlined'}
                {...register('email', { required: true })}
              />

              <LoadingButton
                loading={loading}
                type={'submit'}
                variant={'outlined'}
              >
                {t('Subscribe')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </form>

    </Stack>
  )
}
