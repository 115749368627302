import React from 'react'
import { useRecoilState } from 'recoil'

import Rest, { ListResponse } from 'modules/rest'
import { Workspace } from 'types/workspace'
import isEmpty from 'utils/isEmpty'

import { workspacesAtom } from './workspace.atom'
import { Invalidate } from '.'

type Workspaces = Invalidate<Array<Workspace>> & {
  workspaces: Array<Workspace>;
}

export default function useWorkspaces(): Workspaces {

  const [workspaces, setWorkspaces] = useRecoilState(workspacesAtom)

  const fetchWorkspaces = React.useCallback(async (force = false): Promise<Array<Workspace>> => {

    if (!isEmpty(workspaces) && !force) {
      return workspaces as Array<Workspace>
    }

    try {
      const response: ListResponse<Workspace> = await Rest.workspaces.all()

      setWorkspaces(response.results as Array<Workspace>)

      return response.results
    } catch {
      return []
    }
  }, [])

  function invalidate() {
    return fetchWorkspaces(true)
  }

  return {
    workspaces,
    invalidate
  }
}
