import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'

import useMe from 'modules/state/me.hook'

export default function AuthPage() {

  const location = useLocation()
  const navigate = useNavigate()

  const me = useMe()

  const { pathname } = location
  useEffect(() => {
    if (me.isLoggedIn) {
      // Handle when a user is logged in but is on some auth page
      navigate('/', { replace: true })
    }
  }, [pathname, me.isLoggedIn])

  return (
    <Stack
      alignItems={'center'}
      py={{
        xs: 3,
        sm: 10
      }}>
      <Card
        sx={{
          width: {
            xs: '100%',
            sm: '400px'
          }
        }}>
        <Stack p={3}>
          <Outlet />
        </Stack>
      </Card>
    </Stack>
  )
}
