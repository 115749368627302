import React from 'react'
import { useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar, { imageListItemBarClasses } from '@mui/material/ImageListItemBar'
import { Plus } from 'lucide-react'

import rest from 'modules/rest'
import { Media } from 'types/media'

interface MediaListProps {
  onChange: (media: Media) => void
  selectedMedia?: Media[]
}

function MediaList({ onChange, selectedMedia }:MediaListProps) {
  const params = useParams()
  const page_size = 8

  const alreadyAddedIds = selectedMedia?.map((media) => media.id) || []

  const [page, setPage] = React.useState<{
    currentPage: number
    count: number
    results: Media[]
  }>({
    currentPage: 1,
    count: 0,
    results: []
  })

  const loadMedia = React.useCallback((page: number) => {
    rest.media.all({ workspace: params.workspaceId, page, page_size }).then((result) => {

      setPage((prevState) => ({
        currentPage: page,
        count: result.count,
        results: [
          ...prevState.results,
          ...result.results
        ]
      }))
    })
  }, [page])

  const refreshMediaList = React.useCallback(() => {
    loadMedia(1)
  }, [])

  React.useEffect(() => {
    refreshMediaList()
    return () => {
      setPage({
        currentPage: 1,
        count: 0,
        results: []
      })
    }
  }, [])

  const addMedia = React.useCallback((item: Media) => {
    onChange(item)
  }, [])

  return (
    <>
      <ImageList
        cols={4}
        rowHeight={300}
        variant={'quilted'}
      >
        {page.results.map((item) => (

          <ImageListItem
            key={item.id}
            sx={{
              [`&:hover .${imageListItemBarClasses.root}`]: {
                visibility: 'visible'
              }
            }} >
            <img
              alt={item.id}
              loading={'lazy'}
              src={`${process.env.REACT_APP_MEDIA_ROOT}${item.file}`}
              srcSet={`${process.env.REACT_APP_MEDIA_ROOT}${item.file}`}
            />
            {!alreadyAddedIds.includes(item.id) && (
              <ImageListItemBar
                position={'top'}
                actionIcon={
                  <IconButton onClick={() => addMedia(item)}>
                    <Plus size={18}/>
                  </IconButton>
                }
                sx={{
                  p: 0.5
                }}
              />)}
          </ImageListItem>
        )
        )}
      </ImageList>

      {(page.currentPage * page_size < page.count) && (
        <Button onClick={() => loadMedia(page.currentPage + 1)}>
          Load more
        </Button>
      )}
    </>
  )
}

export default MediaList
