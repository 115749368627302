import React from 'react'

import rest from 'modules/rest'
import { BlockContentModel } from 'types/block-content-model'

function useBlockContentModels(): BlockContentModel[] {

  // TODO: Add it into recoil with `workspace_type` persist
  const [blockContentModels, setBlockContentModels] = React.useState<BlockContentModel[]>([])

  React.useEffect(() => {

    rest.blockContentModels.all().then((response) => {
      setBlockContentModels(response)
    })

  }, [])

  return blockContentModels
}

export default useBlockContentModels
