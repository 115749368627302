import React from 'react'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

interface LogoProps {
  size?: 'small' | 'medium' | 'large'
}

export default function Logo({ size = 'medium' }: LogoProps) {
  const theme = useTheme()
  const fillColor = theme.palette.text.primary

  let svgProps = { height: 26, width: 24 }
  let fontVariant: 'h6' | 'h5' = 'h6'
  switch (size) {

  case 'medium':
    svgProps = {
      height: 30, width: 28
    }
    fontVariant = 'h5'
  }

  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      gap={1}>
      <svg
        fill={fillColor}
        viewBox={'0 0 512 512'}
        {...svgProps}
      >
        <path
          d={'M220.815 99.123c22.356 128.48 55.89 128.48 78.245 0C310.238 27.938 310.238 26.2 259.937 26.2c-50.3 0-50.3 1.737-39.122 72.922zM355.323 89.869c-55.89 102.437-46.575 114.59 37.26 69.448 40.985-22.57 74.519-45.141 74.519-50.35 11.178-13.89-5.589-53.823-31.67-67.713l-12.464-6.719-15.594-4.219-18.499 5.539-11.215 12.327-22.356 41.67zM102.701 46.81C39.36 93.688.237 178.763 2.1 263.838c1.863 43.405 5.589 52.086 11.178 22.57 3.726-24.306 11.178-71.184 16.766-104.172 7.452-36.461 35.397-79.867 76.383-116.327C179.084-.068 179.084-8.75 102.701 46.81z'}/>
        <path
          d={'M91.523 116.26c-22.356 38.196-22.356 38.196 70.794 95.491 26.082 17.362 20.493 17.362-29.808 1.736-48.438-13.89-65.205-13.89-78.246 5.21-42.848 64.24-5.589 95.491 83.835 67.712 80.108-24.307 91.286-50.35 54.027-137.162-33.534-79.866-67.068-90.283-100.602-32.988zM380.845 213.661c-33.533 13.89-61.478 27.78-61.478 32.988 0 10.418 126.683 60.768 150.902 60.768 9.315 0 16.767-27.78 16.767-60.768 0-67.712-13.041-71.185-106.19-32.988zM141.824 307.243c-74.52 34.725-74.52 36.461-48.438 85.075 27.945 48.614 63.342 39.933 100.602-24.307 16.767-29.516 26.081-41.67 20.492-24.307-35.396 116.327-35.396 116.327 27.945 116.327 63.342 0 67.068-8.681 29.808-130.217-5.589-19.098 3.726-5.208 22.356 29.516 35.397 72.921 67.068 83.339 96.876 32.988 26.081-45.142 26.081-45.142-31.671-81.602-68.93-41.67-135.999-41.67-217.97-3.473z'}/>
        <path
          d={'M18.867 350.649c0 34.724 89.423 121.535 149.039 144.106 83.834 32.988 173.258 8.681 240.325-62.504 31.671-32.988 57.753-69.449 57.753-81.602 0-12.154-14.904 1.736-33.534 31.252-48.437 71.185-81.971 95.492-158.354 107.645-81.971 12.154-171.395-31.252-217.97-104.173-20.493-31.252-37.26-46.878-37.26-34.724z'}/>
      </svg>
      <Typography variant={fontVariant}>
        {process.env.REACT_APP_NAME}
      </Typography>
    </Stack>
  )
}
