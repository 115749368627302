import React from 'react'
import { useLocation } from 'react-router-dom'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import { paperClasses } from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Boxes, PackagePlus } from 'lucide-react'
import { useRecoilValue } from 'recoil'

import { workspaceSelectedAtom } from 'modules/state/workspace.atom'
import get from 'utils/get'

import { SideMenuContext, SideMenuSectionsType } from './side-menu.context'
import SideMenuDrawerItem from './side-menu-drawer-item'
import profileItems from './side-menu-drawer-profile'
import SideMenuDrawerUser from './side-menu-drawer-user'
import workspaceItems from './side-menu-drawer-workspace'
import SideMenuWorkspaceSelect from './side-menu-workspace-select'

const drawerWidth = 240

function getMenuListItems(pathname: string): Array<[string, React.ReactElement]> {

  if (pathname.startsWith('/profile')) {
    return profileItems
  }

  if (pathname === '/workspaces/create') {
    return [
      [
        'workspaces',
        <SideMenuDrawerItem
          href={'/workspaces'}
          icon={<Boxes />}
          text={'Workspaces'} />
      ]

    ]
  }

  const workspaceMenu = pathname.match('/workspaces/[\\w|\\d|-]*')
  if (workspaceMenu) {
    return workspaceItems(workspaceMenu[0])
  }

  // Root view
  return [
    [
      'workspaces',
      <SideMenuDrawerItem
        href={'/workspaces'}
        icon={<Boxes />}
        text={'Workspaces'} />
    ],
    [
      'workspaces-create',
      <SideMenuDrawerItem
        href={'/workspaces/create'}
        icon={<PackagePlus />}
        text={'Create workspace'} />
    ]
  ]
}

interface WorkspaceSideMenuDrawerProps {
  isOpen: boolean
  toggleVisibility: (isOpen: boolean) => void
}

export const SIDE_MENU_CONTEXT_DEFAULTS = {
  workspaceSectionOpen: false,
  workspaceSectionSocialOpen: false
}

export default function SideMenuDrawer({ isOpen, toggleVisibility }: WorkspaceSideMenuDrawerProps) {
  const location = useLocation()
  const isPermanentDrawer = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const workspaceSelected = useRecoilValue(workspaceSelectedAtom)

  const [menuSections, setSideMenuSectionsOpen] = React.useState<SideMenuSectionsType>(SIDE_MENU_CONTEXT_DEFAULTS)

  function setMenuSectionOpen(sectionKey: string) {
    setSideMenuSectionsOpen((prev) => ({
      ...prev,
      [sectionKey]: !Boolean(get(prev, [sectionKey], false))
    }))
  }

  const { pathname } = location

  const menuItems = getMenuListItems(pathname)

  React.useEffect(() => {
    setSideMenuSectionsOpen(SIDE_MENU_CONTEXT_DEFAULTS)
  }, [workspaceSelected?.id])

  return (
    <MuiDrawer
      anchor={'left'}
      onClose={toggleVisibility}
      open={isOpen}
      variant={isPermanentDrawer ? 'permanent' : 'temporary'}
      PaperProps={{
        elevation: 0
      }}
      sx={{
        // display: {
        //   xs: isOpen ? 'block': 'none',
        //   sm: 'block'
        // },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth
        },
        [`& .${paperClasses.root}`]: {
          backgroundColor: 'background.default',
          border: 'none',
          justifyContent: 'space-between'
        }
      }}
    >
      <Stack
        sx={{
          flexGrow: 1,
          paddingTop: 'var(--Header-height, 0)',
          justifyContent: 'space-between'
        }}>
        <Stack>
          <SideMenuWorkspaceSelect />
          <SideMenuContext.Provider
            value={{
              menuSections,
              setMenuSectionOpen
            }}>
            <List dense>
              {menuItems.map(([key, item]) => (
                <React.Fragment key={key}>
                  {item}
                </React.Fragment>
              ))}
            </List>
          </SideMenuContext.Provider>
        </Stack>
        <SideMenuDrawerUser />
      </Stack>
    </MuiDrawer>

  )
}
