import React from 'react'
import AuthPage from 'pages/auth'
import ActivationPage from 'pages/auth/activation'
import ForgotPasswordPage from 'pages/auth/forgot-password'
import Login from 'pages/auth/login'
import Register from 'pages/auth/register'

import RouteRedirect from 'components/route-redirect'

const authRouter = {
  path: 'auth',
  element: (
    <RouteRedirect
      redirectTo={'/workspaces'}
      isAuthenticated>
      <AuthPage />
    </RouteRedirect>
  ),
  children: [{
    path: 'register',
    element: <Register />
  }, {
    path: 'forgot-password',
    element: <ForgotPasswordPage />
  }, {
    path: 'activation/:key',
    element: <ActivationPage />
  }, {
    path: '',
    element: <Login />
  }]
}

export default authRouter
