import { v5 as uuidv5 } from 'uuid'
export default function toHash(value: string| undefined = undefined): string | null {

  if (value === undefined) {
    return null
  }

  const MY_NAMESPACE = 'dfd7da59-a4eb-4137-a3ae-461a8147f653'

  return uuidv5(value, MY_NAMESPACE).replaceAll('-', '')

}
