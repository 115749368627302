import React from 'react'
import { useLoaderData } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import Block from 'components/block'
import { Block as BlockType } from 'types/block'
import { BlockContentModel } from 'types/block-content-model'
import { Page } from 'types/page'

import AddPageBlock from './add-page-block/add-page-block'
import PageInfo from './page-info'

export default function WorkspacePagesDetailPage() {
  const page = useLoaderData<Page>()

  const [pageBlocks, setPageBlocks] = React.useState<Array<BlockType>>(page.page_blocks)

  const addInitialBlock = React.useCallback((blockContentModel: BlockContentModel) => {
    setPageBlocks((prevState) => [
      ...prevState, {
        block_content_model: blockContentModel,
        data: {},
        order: prevState.length
      }
    ])
  }, [pageBlocks])

  return (
    <Stack gap={2}>
      <Typography
        variant={'h4'}>
        {page.title}
      </Typography>
      <PageInfo {...page} />
      <div>
        {pageBlocks.map((block, idx) => (
          <Block
            key={block.order}
            order={idx}
            {...block} />
        ))}
      </div>
      <AddPageBlock addInitialBlock={addInitialBlock} />
    </Stack>
  )
}
