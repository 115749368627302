import changeHeightWidth from './changeHeightWidth'
import config from './config'

function resizeAndRotateImage(image: HTMLImageElement, rotation = false) {
  const qualityDecimal = config.quality / 100
  const canvas = document.createElement('canvas')

  let width = image.width
  let height = image.height

  const newHeightWidth = changeHeightWidth(
    height,
    width
  )

  canvas.width = newHeightWidth.width
  canvas.height = newHeightWidth.height

  width = newHeightWidth.width
  height = newHeightWidth.height

  const ctx = canvas.getContext('2d')
  if (!ctx) {
    throw new Error('Canvas is empty')
  }
  ctx.fillRect(0, 0, width, height)

  if (ctx.imageSmoothingEnabled && ctx.imageSmoothingQuality) {
    ctx.imageSmoothingQuality = 'high'
  }

  if (rotation) {

    ctx.rotate(Math.PI / 2)

    let x = Math.abs(canvas.height - canvas.width) / 2
    let y = -(height + x )
    if (canvas.height > canvas.width) {
      y = -(width + x )
    } else {
      x = -x
    }

    ctx.translate(x , y)
  }

  ctx.drawImage(image, 0, 0, width, height)

  return canvas.toDataURL(`image/${config.compressFormat}`, qualityDecimal)
}

export default resizeAndRotateImage
