import { atom } from 'recoil'

import { Workspace } from 'types/workspace'

export const workspacesAtom = atom<Array<Workspace>>({
  key: 'workspacesAtom',
  default: []
})

export const workspaceSelectedAtom = atom<Workspace| undefined>({
  key: 'workspaceSelectedAtom',
  default: undefined
})
