import i18n from 'modules/i18n'
import fileNameHash from 'utils/file-name-hash'

import config from './config'
import resizeAndRotateImage from './resizeAndRotateImage'

function b64toByteArrays(b64Data: string, contentType: string) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  contentType = contentType || 'image/jpeg'
  const sliceSize = 512

  // TODO: Change signature when using RN
  const byteCharacters = window.atob(
    b64Data.toString().replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, '')
  )
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }
  return byteArrays
}

function b64toFile(b64Data: string, fileName: string, contentType: string) {
  const byteArrays = b64toByteArrays(b64Data, contentType)
  return new File(byteArrays, fileName, { type: contentType, lastModified: new Date().getTime() })
}

function createResizedImage(file: File, rotation: boolean): Promise<File> {

  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    if (file) {
      if (file.type && !file.type.includes('image')) {
        throw Error(i18n.t('File is not an image!'))
      } else {

        reader.readAsDataURL(file)

        reader.onload = () => {
          const image = new Image()
          if (typeof reader.result !== 'string') {
            reject(i18n.t('Oops something went wrong, please try again.'))
            return
          }
          image.src = reader.result
          image.onload = function() {

            const resizedDataUrl = resizeAndRotateImage(image, rotation)
            const compressFormat = config?.compressFormat || 'jpeg'

            const contentType = `image/${compressFormat}`

            const fileName = fileNameHash(file)

            const newFileName = `${fileName}.${compressFormat}`

            const newFile = b64toFile(resizedDataUrl, newFileName, contentType)

            resolve(newFile)
          }
        }
        reader.onerror = (error) => {
          reject(error)
        }
      }
    } else {
      reject(i18n.t('File not found!'))
    }
  })
}

export default createResizedImage
