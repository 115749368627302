import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import Link from '@mui/material/Link'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import { typographyClasses } from '@mui/material/Typography'

import { SideMenuContext, SideMenuSectionsType } from './side-menu.context'

interface SideMenuDrawerItemProps {
  text: string
  textSecondary?: string
  icon: React.ReactNode
  href?: string
  action?: () => void
  isOpenKey?: keyof SideMenuSectionsType
  color?: 'error' | 'success' | 'warning' | 'info'
  disableSelect?: boolean
}

const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'color'
})<Pick<SideMenuDrawerItemProps, 'color'>>(({ theme, color }) => color && ({
  'svg': {
    stroke: theme.palette[color].light
  }
}))

const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'color'
})<Pick<SideMenuDrawerItemProps, 'color'>>(({ theme, color }) => color && ({
  [`.${typographyClasses.root}`]: {
    color: theme.palette[color].light
  }
}))

export default function SideMenuDrawerItem({ text, textSecondary, icon, href, action, isOpenKey, color, disableSelect = false }: SideMenuDrawerItemProps) {
  const location = useLocation()

  const { menuSections } = useContext(SideMenuContext)

  if (isOpenKey && !menuSections[isOpenKey]) {
    return null
  }

  const { pathname } = location

  const selected = !disableSelect && pathname === href

  return (
    <ListItem
      sx={{ display: 'block' }}
      disablePadding>
      <ListItemButton
        component={Link}
        href={href}
        selected={selected}
        onClick={() => {
          if (action) {
            action()
          }
        }}
        sx={{
          textTransform: 'none'
        }}>
        <StyledListItemIcon
          color={color}>
          {icon}
        </StyledListItemIcon>
        <StyledListItemText
          color={color}
          primary={text}
          secondary={textSecondary}
        />
      </ListItemButton>
    </ListItem>

  )
}
