import React from 'react'
import { useLoaderData } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import Block from 'components/block'
import { Block as BlockType } from 'types/block'
import sort from 'utils/sort'

export default function WorkspaceComponentsPage() {
  const components: BlockType[] = useLoaderData()

  return (
    <Stack gap={2}>
      <Typography
        variant={'h4'}>
        Components
      </Typography>
      <div>
        {components.sort(sort('order')).map((block) => (
          <Block
            key={block.block_content_model.id}
            {...block} />
        ))}
      </div>
    </Stack>
  )
}
