import useWorkspaceObserver from './use-workspace-observer.hook'

function StateObservers() {

  useWorkspaceObserver()

  return null
}

export default StateObservers
