import React from 'react'

export type SideMenuSectionsType = {
  workspaceSectionOpen: boolean
  workspaceSectionSocialOpen: boolean
}

interface SideMenuContextType {
  setMenuSectionOpen: (sectionKey: keyof SideMenuSectionsType) => void
  menuSections: SideMenuSectionsType
}

export const SideMenuContext = React.createContext<SideMenuContextType>({
  setMenuSectionOpen: (sectionKey: string) => {},
  menuSections: {
    workspaceSectionOpen: false,
    workspaceSectionSocialOpen: false
  }
})
