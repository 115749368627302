import React from 'react'
import { FieldError } from 'react-hook-form/dist/types/errors'
import FormControl from '@mui/material/FormControl'
import TextField , { TextFieldProps } from '@mui/material/TextField'

import isEmpty from 'utils/isEmpty'
import omit from 'utils/omit'

import { FormFieldType } from './form-field.type'
import FormFieldHelp from './form-field-help.components'

type FormTextareaProps = FormFieldType & TextFieldProps & {
  errors?: FieldError
}

function FormTextarea({ errors, ...props }: FormTextareaProps, ref: React.ForwardedRef<HTMLInputElement>) {

  const hasErrors = !isEmpty(errors)

  return (
    <FormControl
      error={hasErrors}>
      <TextField
        ref={ref}
        error={hasErrors}
        rows={4}
        {...omit(props, ['watch'])}
        multiline
      />
      <FormFieldHelp
        errors={errors}
        {...props} />
    </FormControl>
  )
}

export default React.forwardRef(FormTextarea)
