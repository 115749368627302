import React from 'react'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'

import FacebookIcon from 'components/icons/facebook.icon'
import InstagramIcon from 'components/icons/instagram.icon'
import XIcon from 'components/icons/x.icon'

export default function FooterSocial() {

  return (
    <Stack
      direction={'row'}
      gap={4}>
      <Link
        color={'textPrimary'}
        href={'https://facebook.com'}
        rel={'noopener noreferrer'}
        target={'_blank'}>
        <FacebookIcon />
      </Link>
      <Link
        color={'textPrimary'}
        href={'https://instagram.com'}
        rel={'noopener noreferrer'}
        target={'_blank'}>
        <InstagramIcon />
      </Link>
      <Link
        color={'textPrimary'}
        href={'https://instagram.com'}
        rel={'noopener noreferrer'}
        target={'_blank'}>
        <XIcon />
      </Link>
    </Stack>
  )
}
