import React from 'react'
import Box from '@mui/material/Box'
import Button, { buttonClasses } from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { MessageSquareText, Package, PanelsTopLeft } from 'lucide-react'

import WorkspaceIcon from 'components/workspace-icon'
import { Workspace } from 'types/workspace'

type WorkspaceListItemProps = {
  workspace: Workspace
}

export default function WorkspacesListItem({ workspace }: WorkspaceListItemProps) {

  return (
    <Grid
      size={{ xs: 12 }}
      sx={{
        aspectRatio: '1 / 0.8',
        maxWidth: '400px'
      }}>
      <Card
        variant={'outlined'}
        sx={{
          minWidth: '180px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: 0
        }}>
        <CardContent
          sx={{
            padding: '0 !important',
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            [`.${buttonClasses.root}`]: {
              color: 'text.primary'
            }
          }}>
          <Stack
            gap={1}
            padding={2}
          >
            <Stack
              alignItems={'center'}
              direction={'row'}
              gap={1}>
              <Typography
                color={'text.secondary'}
                variant={'caption'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <WorkspaceIcon
                  type={workspace.type} />

                {workspace.type}
              </Typography>
            </Stack>

            <Stack>
              <Typography
                component={'div'}
                variant={'h6'}>
                {workspace.title}
              </Typography>
            </Stack>

            <Typography
              variant={'body2'}
              sx={{
                WebkitLineClamp: '3',
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
                wordBreak: 'break-word',
                overflow: 'hidden',
                color: 'text.secondary'
              }}>
              {workspace.description}
            </Typography>

            <Stack
              direction={'row'}
              gap={1}>
              <Chip
                label={'Tag 1'}
                size={'small'}
                variant={'outlined'} />

              <Chip
                label={'Tag 2'}
                size={'small'}
                variant={'outlined'} />
            </Stack>
          </Stack>

          <Box flexGrow={1} />

          <Stack
            direction={'row'}
            px={2}
            py={1}>
            <Button
              component={Link}
              href={`/workspaces/${workspace.id}/inquiries`}
              size={'small'}
              startIcon={<MessageSquareText size={18} />}
            >
                  23
            </Button>
          </Stack>

          <Divider />

          <Stack
            direction={'row'}
            gap={1}
            px={2}
            sx={{
              [`.${buttonClasses.root}`]: {
                marginY: 1
              } }}
          >
            <Button
              component={Link}
              href={`/workspaces/${workspace.id}`}
              startIcon={<Package />}
            >
                  Details
            </Button>

            <Button
              component={Link}
              href={`/workspaces/${workspace.id}/pages`}
              startIcon={<PanelsTopLeft />}>
                  Pages
            </Button>
            {/*<Divider orientation={'vertical'} />*/}
            {/*<Button*/}
            {/*  component={Link}*/}
            {/*  href={`/workspaces/${workspace.id}`}*/}
            {/*  startIcon={<ChartLine />}*/}
            {/*>*/}
            {/*  Statistic*/}
            {/*</Button>*/}
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  )
}
