import React from 'react'
import Typography from '@mui/material/Typography'

export default function WorkspaceSettingsPage() {

  return (
    <Typography>
      WorkspaceSettingsPage
      <br/>
      i18n on / off

    </Typography>
  )
}
