import React from 'react'
import { useParams } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'

import fileResizer from 'modules/file-resizer'
import rest from 'modules/rest'

import { notify } from '../../../../components/snackbar'

interface MediaUploadProps {
  refreshMediaList: () => void
}

export default function MediaUpload({ refreshMediaList }: MediaUploadProps) {
  const params = useParams()

  const inputRef = React.useRef<HTMLInputElement | null>()

  const [uploading, setUploading] = React.useState(false)

  const handleUploadClick = React.useCallback(() => {
    inputRef?.current?.click()
  }, [])

  const onInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setUploading(true)
    const files = e.target.files

    const filesMap: Record<string, File> = {}

    Promise.all(Object.values(files || {}).map(async (file) => {
      const resized = await fileResizer(file, false)
      filesMap[resized.name] = resized

    })).then(() => {
      return Promise.allSettled(Object.keys(filesMap).map( async (key) => {

        const file = await fileResizer(filesMap[key],false)
        const formData = new FormData()
        formData.append('file', file)
        formData.append('workspace', params.workspaceId || '')

        return rest.media.post(formData, {})
      }))
    }).catch(() => {
      notify('Oops something went wrong, please try again.', 'error')
    }).finally(() => {
      setUploading(false)
      refreshMediaList()
    })
  }, [])

  return (
    <Stack direction={'row'}>

      <LoadingButton
        loading={uploading}
        onClick={handleUploadClick}>
        Upload
      </LoadingButton>

      <input
        ref={(ref) => {
          inputRef.current = ref
        }}
        accept={'image/*'}
        autoComplete={'off'}
        onChange={onInputChange}
        type={'file'}
        style={{
          display: 'none'
        }}
        multiple
      />
    </Stack>
  )
}
