import React, { useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { inputAdornmentClasses } from '@mui/material/InputAdornment'
import { inputBaseClasses } from '@mui/material/InputBase'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { CircleMinus } from 'lucide-react'

import { FormTextareaField, FormTextField } from 'components/form/form-field'

import useWorkspaceAPITokenCreate from './workspace-api-tokens-create.hook'

export const StyledFormTextField = styled(FormTextField)(({ theme }) => ({
  [`.${inputBaseClasses.root}`]: {
    paddingRight: 0
  },
  [`.${inputAdornmentClasses.root}`]: {
    display: 'none'
  },
  '&:hover': {
    [`.${inputBaseClasses.root}`]: {
      paddingRight: theme.spacing(2)
    },
    [`.${inputAdornmentClasses.root}`]: {
      display: 'inline-flex'
    }
  }
}))

export default function WorkspaceApiTokensCreatePage() {

  const {
    register,
    handleSubmit,
    formState: { errors },
    loading,
    control
  } = useWorkspaceAPITokenCreate()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'allowed_origins' as never
  })

  useEffect(() => {
    append('')
  }, [])

  return (
    <Stack>
      <Typography
        variant={'h4'}>
        Create API Token
      </Typography>
      <form
        onSubmit={handleSubmit}
        noValidate>

        <Stack
          gap={2}
          pt={4}
          width={{
            xs: '100%',
            md: '50%'
          }}>
          <FormTextField
            errors={errors.title}
            label={'Title'}
            {...register('title', { required: true })}
          />
          <FormTextareaField
            errors={errors.description}
            label={'Description'}
            rows={3}
            {...register('description')}
          />
          <Stack
            alignItems={'center'}
            direction={'row'}
            justifyContent={'space-between'}
          >
            <Stack>
              <Typography >
                Allowed origins
              </Typography>
              <Typography
                color={'textSecondary'}
                variant={'body2'}>
                Example: https://example.com
              </Typography>
            </Stack>
            <Button
              onClick={() => {
                append('')
              }}>
              Add
            </Button>
          </Stack>

          {fields.map((field, index) => (
            <StyledFormTextField
              key={field.id}
              label={'Origin'}
              {...register(`allowed_origins.${index}`)}
              endAdornment={
                <IconButton
                  color={'error'}
                  onClick={() => remove(index)} >
                  <CircleMinus />
                </IconButton>
              }
            />
          ))}
          <Stack
            alignItems={'flex-end'}
            py={2}
          >
            <LoadingButton
              loading={loading}
              type={'submit'}
              variant={'outlined'}>
              Create
            </LoadingButton>
          </Stack>
        </Stack>

      </form>
    </Stack>
  )
}
