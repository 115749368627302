import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export default function FooterLinks() {
  const { t } = useTranslation()

  return (
    <Stack
      gap={0.5}
    >
      <Typography
        color={'textPrimary'}
        variant={'body1'}>
        {t('Company')}
      </Typography>
      <Link
        href={'/support/about'}>
        {t('About')}
      </Link>
      <Link
        href={'/support/help'}>
        {t('Help')}
      </Link>
      <Link
        href={'/support/privacy'}>
        {t('Privacy')}
      </Link>
      <Link
        href={'/support/terms'}>
        {t('Terms')}
      </Link>
      <Link
        href={'/support/rules'}>
        {t('Rules')}
      </Link>
    </Stack>
  )
}
