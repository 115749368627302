import React from 'react'
import { FieldError } from 'react-hook-form/dist/types/errors'
import FormHelperText from '@mui/material/FormHelperText'

import isEmpty from 'utils/isEmpty'

import { FormFieldType } from './form-field.type'

type FormTextareaProps = FormFieldType & {
  errors?: FieldError
}

function FormFieldHelp({ errors, help, variant }: FormTextareaProps) {

  const isRequired = errors?.type === 'required'
  const apiError = errors?.type === 'api'

  const hasErrors = !isEmpty(errors)

  return (
    <>
      {isRequired && (
        <FormHelperText
          variant={variant}>
          This field is required
        </FormHelperText>
      )}

      {apiError && (
        <FormHelperText
          variant={variant}>
          {errors?.message}
        </FormHelperText>
      )}

      {!hasErrors && help && (
        <FormHelperText
          variant={variant}>
          {help}
        </FormHelperText>
      )}
    </>
  )
}

export default FormFieldHelp
