import Button, { ButtonProps } from '@mui/material/Button'
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from 'notistack'

export function notify(message: string, variant: 'default' | 'error' | 'success' | 'warning' | 'info' = 'default') {
  function action(snackbarId: SnackbarKey){
    let color: ButtonProps['color'] = variant === 'default' ? 'primary' : variant || 'primary'

    return (
      <>
        <Button
          color={color}
          onClick={() => { closeSnackbar(snackbarId) }}
          variant={'text'}>
          Dismiss
        </Button>
      </>
    )
  }

  return enqueueSnackbar(message, { variant, action })
}
