import React from 'react'
import Typography from '@mui/material/Typography'

export default function WorkspaceUsersPage() {

  return (
    <Typography>
      Workspace Users
    </Typography>
  )
}
