import React from 'react'
import { Navigate } from 'react-router-dom'
import LandingPage from 'pages/landing'

import RouteRedirect from 'components/route-redirect'

import authRouter from './root-auth.router'
import profileRouter from './root-profile.router'
import supportRouter from './root-support.router'
import workspacesRouter from './root-workspaces.router'

const rootRouter = [
  authRouter,
  profileRouter,
  workspacesRouter,
  supportRouter,
  {
    path: '',
    element: (
      <RouteRedirect
        redirectTo={'/workspaces'}
        isAuthenticated>
        <LandingPage />
      </RouteRedirect>
    )
  },
  {
    path: '*',
    element: <Navigate
      to={'/'}
      replace />
  }
]

export default rootRouter
