import toHash from './to-hash'

export default function fileNameHash(file: File | null): string | null {
  if (!file) {
    return null
  }

  return toHash([file.name, file.type, file.lastModified.toString(), file.size.toString()].join(''))

}
