import React from 'react'
import Typography from '@mui/material/Typography'

export default function LandingPage() {

  return (
    <>
      <Typography>
          Landing page
      </Typography>
    </>
  )
}
