import React from 'react'
import { useForm } from 'react-hook-form'

import { notify } from 'components/snackbar'
import rest from 'modules/rest'
import { WorkspaceAccess } from 'types/workspace'
import omit from 'utils/omit'

type UserInputs = {
  title: string
  description: string
  allowed_origins: Array<string>
}

export function useWorkspaceAccess(workspaceAccess: WorkspaceAccess) {
  const [loading, setLoading] = React.useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control
  } = useForm<UserInputs>({
    defaultValues: {
      ...omit(workspaceAccess, ['api_key'])
    }
  })

  function save(data: UserInputs) {
    if (!workspaceAccess?.id) {
      return
    }

    setLoading(true)
    rest.workspacesAccesses.patch(workspaceAccess.id, {
      ...data
    }).then(() => {
      notify('Updated', 'success')
    }).catch((error) => {
      notify(error.message, 'error')
    }).finally(() => {
      setLoading(false)
    })
  }

  return {
    register,
    handleSubmit: handleSubmit(save),
    formState: { errors, isDirty },
    control,
    loading
  }
}
