import React from 'react'
import ProfilePage from 'pages/profile'
import ProfileBillingPage from 'pages/profile/profile-billing'
import ProfileOverviewPage from 'pages/profile/profile-overview'
import ProfilePasswordPage from 'pages/profile/profile-password'
import ProfileSettingsPage from 'pages/profile/profile-settings'

import RouteRedirect from 'components/route-redirect'

const profileRouter = {
  path: 'profile',
  element: (
    <RouteRedirect
      isAuthenticated={false}
      redirectTo={'/'}>
      <ProfilePage />
    </RouteRedirect>
  ),
  children: [
    {
      path: 'settings',
      element: <ProfileSettingsPage />
    },
    {
      path: 'billing',
      element: <ProfileBillingPage />
    }, {
      path: 'password',
      element: <ProfilePasswordPage />
    }, {
      path: '',
      element: <ProfileOverviewPage />
    }
  ]
}

export default profileRouter
