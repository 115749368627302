import React from 'react'
import {
  Boxes,
  Image,
  KeyRound,
  PanelsTopLeft,
  Puzzle,
  TriangleAlert
} from 'lucide-react'

import SideMenuDrawerItem from './side-menu-drawer-item'

function workspaceItems(pathname: string): Array<[string, React.ReactElement]> {

  return [
    [
      'workspaces',
      <SideMenuDrawerItem
        href={'/workspaces'}
        icon={<Boxes />}
        text={'Workspaces'} />
    ],
    // TODO: Define Overview page #47
    // [
    //   'workspace-overview',
    //   <SideMenuDrawerItem
    //     href={pathname}
    //     icon={<Package />}
    //     text={'Overview'} />
    // ],
    [
      'workspace-pages',
      <SideMenuDrawerItem
        href={`${pathname}/pages`}
        icon={<PanelsTopLeft />}
        text={'Pages'} />
    ],
    [
      'workspace-components',
      <SideMenuDrawerItem
        href={`${pathname}/components`}
        icon={<Puzzle />}
        text={'Components'} />
    ],
    // TODO: Define SEO page #48
    // [
    //   'workspace-seo',
    //   <SideMenuDrawerItem
    //     href={`${pathname}/seo`}
    //     icon={<Binoculars />}
    //     text={'SEO'} />
    // ],
    // Define Social pages #49
    // [
    //   'workspace-social-lbl',
    //   <SideMenuDrawerLabel
    //     openKey={'workspaceSectionSocialOpen'}
    //     text={'Social'}
    //   />
    // ],
    // [
    //   'workspace-inquiries',
    //   <SideMenuDrawerItem
    //     href={`${pathname}/inquiries`}
    //     isOpenKey={'workspaceSectionSocialOpen'}
    //     text={'Inquiry'}
    //     icon={<Badge
    //       badgeContent={23}
    //       color={'error'}>
    //       <MessageSquareText />
    //     </Badge>} />
    // ],
    // [
    //   'workspace-newsletters',
    //   <SideMenuDrawerItem
    //     href={`${pathname}/newsletters`}
    //     icon={<Mails />}
    //     isOpenKey={'workspaceSectionSocialOpen'}
    //     text={'Newsletters'} />
    // ],
    // [
    //   'workspace-users',
    //   <SideMenuDrawerItem
    //     href={`${pathname}/users`}
    //     icon={<Users />}
    //     isOpenKey={'workspaceSectionSocialOpen'}
    //     text={'Users'} />
    // ],
    // TODO: Visible after menu is being crowded
    // [
    //   'workspace-settings-lbl',
    //   <SideMenuDrawerLabel
    //     openKey={'workspaceSectionOpen'}
    //     text={'Settings'}
    //   />
    // ],
    // TODO: Define settings page #50
    // [
    //   'workspace-settings',
    //   <SideMenuDrawerItem
    //     href={`${pathname}/settings`}
    //     icon={<Settings />}
    //     isOpenKey={'workspaceSectionOpen'}
    //     text={'Settings'}
    //   />
    // ],
    [
      'workspace-media',
      <SideMenuDrawerItem
        href={`${pathname}/media`}
        icon={<Image />}
        // isOpenKey={'workspaceSectionOpen'}
        text={'Uploaded Media'} />
    ],
    [
      'workspace-api-tokens',
      <SideMenuDrawerItem
        href={`${pathname}/api-tokens`}
        icon={<KeyRound />}
        // isOpenKey={'workspaceSectionOpen'}
        text={'API Tokens'}
      />
    ],
    // TODO: Define Members page #51
    // [
    //   'workspace-members',
    //   <SideMenuDrawerItem
    //     href={`${pathname}/members`}
    //     icon={<Users />}
    //     // isOpenKey={'workspaceSectionOpen'}
    //     text={'Members'}
    //   />
    // ],
    [
      'workspace-danger',
      <SideMenuDrawerItem
        color={'error'}
        href={`${pathname}/danger`}
        icon={<TriangleAlert />}
        // isOpenKey={'workspaceSectionOpen'}
        text={'Danger'}
      />
    ]
  ]
}

export default workspaceItems
