import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'

interface HeaderNoAuthProps {
  isLoggedIn: boolean
}

function HeaderNoAuth({ isLoggedIn }: HeaderNoAuthProps) {

  const { t } = useTranslation()

  if (isLoggedIn) {
    return null
  }

  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      gap={2}
    >
      <Button
        color={'primary'}
        component={Link}
        href={'/auth'}

      >
        {t('Login')}
      </Button>
      <Button
        component={Link}
        href={'/auth/register'}
        variant={'contained'}
        sx={{
          display: {
            xs: 'none',
            sm: 'inline-flex'
          }
        }}
      >
        {t('Register')}
      </Button>
    </Stack>
  )
}

export default HeaderNoAuth
