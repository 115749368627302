import React from 'react'
import { Navigate } from 'react-router-dom'

export default function WorkspaceOverviewPage() {
  // TODO: Define Overview page #47

  return <Navigate
    to={'pages'}
    replace />

}
