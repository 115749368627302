import React from 'react'
import { useFieldArray } from 'react-hook-form'
import { useLoaderData } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { CircleMinus } from 'lucide-react'
import { Eye, EyeOff } from 'lucide-react'

import { FormTextareaField, FormTextField } from 'components/form/form-field'
import { WorkspaceAccess } from 'types/workspace'

import { StyledFormTextField } from '../workspace-api-tokens-create/workspace-api-tokens-create.page'

import { useWorkspaceAccess } from './workspace-api-tokens-detail.hook'

export default function WorkspaceApiTokensDetailPage() {
  const workspaceAccess = useLoaderData<WorkspaceAccess>()

  const [apiKeyVisible, setApiKeyVisible] = React.useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    loading,
    control
  } = useWorkspaceAccess(workspaceAccess)

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'allowed_origins' as never
  })

  return (
    <Stack>
      <Typography
        variant={'h4'}>
        Create API Token
      </Typography>
      <form
        onSubmit={handleSubmit}
        noValidate>

        <Stack
          gap={2}
          pt={4}
          width={{
            xs: '100%',
            md: '50%'
          }}>
          <FormTextField
            errors={errors.title}
            label={'Title'}
            {...register('title', { required: true })}
          />
          <FormTextareaField
            errors={errors.description}
            label={'Description'}
            rows={3}
            {...register('description')}
          />
          <Stack
            alignItems={'center'}
            direction={'row'}
            justifyContent={'space-between'}
          >
            <Stack>
              <Typography >
                Allowed origins
              </Typography>
              <Typography
                color={'textSecondary'}
                variant={'body2'}>
                Example: https://example.com
              </Typography>
            </Stack>
            <Button
              onClick={() => {
                append('')
              }}>
              Add Origin
            </Button>
          </Stack>

          {fields.map((field, index) => (
            <StyledFormTextField
              key={field.id}
              label={'Origin'}
              {...register(`allowed_origins.${index}`)}
              endAdornment={
                <IconButton
                  color={'error'}
                  onClick={() => remove(index)} >
                  <CircleMinus />
                </IconButton>
              }
            />
          ))}
          <Card sx={{ my: 2 }}>
            <Stack p={2}>

              <Stack
                alignItems={'center'}
                direction={'row'}
                justifyContent={'space-between'}>
                <Typography>
                  API Key
                </Typography>

                <IconButton onClick={() => setApiKeyVisible(!apiKeyVisible)}>
                  {apiKeyVisible ? <Eye /> : <EyeOff />}
                </IconButton>
              </Stack>
              <Typography
                color={'textSecondary'}>
                {apiKeyVisible ? workspaceAccess.api_key : workspaceAccess.api_key.replace(/./g, '*')}
              </Typography>
            </Stack>
          </Card>
          <Stack
            alignItems={'flex-end'}
            py={2}
          >
            <LoadingButton
              disabled={!isDirty}
              loading={loading}
              type={'submit'}
              variant={'outlined'}>
              Save
            </LoadingButton>
          </Stack>
        </Stack>

      </form>
    </Stack>
  )
}
