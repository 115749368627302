import assign from 'utils/assign'
import join from 'utils/join'

import defaultConverter from './converter'

interface Attributes {
  expires?: unknown
  path?: string
}

class Cookie {
  attributes: Attributes = {
    expires: undefined
  }
  converter = defaultConverter

  constructor(attributes: Attributes) {
    this.attributes = attributes
  }
  set(key: string, value: string = '', attributes: Attributes = {}) {
    if (typeof document === 'undefined') {
      return
    }

    attributes = assign({}, this.attributes, attributes) as Attributes

    if (typeof attributes?.expires === 'number') {
      attributes.expires = new Date(Date.now() + attributes.expires)
    }
    if (attributes?.expires) {
      attributes.expires = (attributes.expires as Date).toUTCString()
    }

    const c_value = join([
      encodeURIComponent(value),
      attributes.expires ? `expires=${attributes.expires}` : null,
      'path=/'
    ].filter((v) => v), '; ')

    document.cookie = key + '=' + c_value
  }

  get(key: string) {
    if (typeof document === 'undefined' || (arguments.length && !key)) {
      return
    }

    const nameEQ = key + '='
    const ca = document.cookie.split(';')

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim()

      if (c.indexOf(nameEQ) === 0) {
        let val = c.substring(nameEQ.length, c.length)
        if (val.indexOf('expires=') === 0) {
          val = val.substring(0, val.indexOf('expires='))
        }
        return val
      }
    }
    return null
  }

  remove(key: string, attributes = {}) {
    this.set(key, '', assign({}, attributes, { expires: -1 }) as Attributes)
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Cookie({ path: '/' })
