import { SelectOption } from 'components/form/form-field/form-select.components'

const blockFieldBackground: {
  field_values: Array<SelectOption>
} = {
  field_values: [{
    'value': 'light',
    'title': 'Light'
  }, {
    'value': 'dark',
    'title': 'Dark'
  }, {
    'value': 'primaryLight',
    'title': 'Primary Light'
  }, {
    'value': 'primaryMain',
    'title': 'Primary Main'
  }, {
    'value': 'primaryDark',
    'title': 'Primary Dark'
  }]
}

export default blockFieldBackground
