import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { notify } from 'components/snackbar'
import rest from 'modules/rest'

export default function ActivationPage() {
  const params = useParams()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!params.key) {
      navigate('/')
    } else {
      rest.activation.post({ key: params.key }).then(() => {
        notify('Account is activated.', 'success')
        navigate('/auth')
      }).catch(() => {
        notify('Activation key has expired.', 'error')
        navigate('/')
      })
    }

  }, [])

  return (
    <Stack>
      <Typography>
        Activating account . . .
      </Typography>
    </Stack>
  )
}
