import React from 'react'
import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'

import Footer from 'components/footer'
import Header from 'components/header'
import Loading from 'components/loading'
import SideMenu from 'components/side-menu'
import StateObservers from 'components/state-observers'
import i18n from 'modules/i18n'
import useMe from 'modules/state/me.hook'

export default function RootPage() {
  const [loading, setLoading] = React.useState(true)
  const me = useMe(false)

  React.useEffect(() => {
    Promise.all([
      i18n,
      me.invalidate()
    ]).finally(() => {
      setLoading(false)
    })

  }, [])

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.default'
      }}>
      <Header/>
      <SideMenu>
        <Outlet/>
      </SideMenu>
      <Box flexGrow={1}/>
      {!me.isLoggedIn && <Footer/>}
      {me.isLoggedIn && <StateObservers /> }
    </Box>
  )
}
