import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { styled, useColorScheme } from '@mui/material/styles'

import apple_black from './assets/apple_black.png'
import apple_white from './assets/apple_white.png'
import google from './assets/google.svg'

const StyledBox = styled(Box)(({ theme }) =>({
  '&:hover': {
    cursor: 'pointer',
    opacity: 0.7
  }
}))

export default function SocialLogin() {
  const { mode } = useColorScheme()

  return (
    <Stack
      direction={'row'}
      gap={6}
      justifyContent={'center'}
    >
      <StyledBox>
        <img
          alt={'google-login'}
          src={google}
          width={'44px'}/>
      </StyledBox>
      <StyledBox>
        <img
          alt={'apple-login'}
          src={mode === 'light' ? apple_black : apple_white}
          width={'44px'}/>
      </StyledBox>
    </Stack>
  )
}
