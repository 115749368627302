import React from 'react'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { FilePlus, Pen, Trash } from 'lucide-react'

import { notify } from 'components/snackbar'
import rest from 'modules/rest/rest'

import useWorkspacePages from './workspace-pages-list.hook'

export default function WorkspacePagesListPage() {
  const { pages, count, invalidate } = useWorkspacePages()

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const deletePage = React.useCallback((id: string | undefined) => () => {
    if (!id) {
      return
    }
    rest.pages.remove(id).then(() => {
      invalidate().then(() => {
        notify('Page was deleted.', 'success')
      })
    })
  }, [])

  return (
    <Stack
      gap={1}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}>
        <Stack>
          <Typography
            variant={'h4'}>
            Pages
          </Typography>

          <Typography color={'textSecondary'}>
            Number of pages found: {count}
          </Typography>
        </Stack>

        <Button
          component={Link}
          href={'create'}
          startIcon={<FilePlus />}
          variant={'outlined'}
        >
          New Page
        </Button>
      </Stack>

      <Stack>
        <TableContainer>
          <Table
            aria-label={'simple table'}
            stickyHeader>
            <TableHead>
              <TableRow
                sx={{
                  '& th': {
                    borderWidth: '2px',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    minWidth: '100px',
                    maxWidth: '250px'
                  } }}>
                <TableCell>Title</TableCell>
                {isLargeScreen && <TableCell>URL Path</TableCell> }
                {isLargeScreen && <TableCell>State</TableCell> }
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {pages.map((page) => (
                <TableRow
                  key={page.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component={'th'}
                    scope={'row'}>
                    {page.title}
                  </TableCell>

                  {isLargeScreen && <TableCell>{page.url_path}</TableCell>}

                  {isLargeScreen && (
                    <TableCell>
                      <Chip
                        color={page.in_draft ? 'warning' : 'success'}
                        label={page.in_draft ? 'In Draft' : 'Live'}
                        size={'small'}
                        variant={'outlined'} />
                    </TableCell>
                  )}

                  <TableCell>
                    <Stack
                      direction={'row'}
                      gap={2}>

                      <IconButton
                        component={Link}
                        href={page.id}
                        size={'small'}>
                        <Pen size={18} />
                      </IconButton>

                      <IconButton
                        onClick={deletePage(page?.id)}
                        size={'small'}>
                        <Trash size={18} />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  )
}
