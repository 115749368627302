import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FieldError } from 'react-hook-form/dist/types/errors'

import BlockLink from 'components/block-link'
import { FormCheckboxField, FormSelectField, FormTextareaField, FormTextField } from 'components/form/form-field'
import MediaSelect from 'components/media-select'
import { BlockContentModelField } from 'types/block-content-model'

import blockFieldBackground from './block-field-background'

interface BlockFieldProps {
  name: string
  model: BlockContentModelField
  loading: boolean
}

export default function BlockField({ name, model }: BlockFieldProps) {
  const {
    register, control,
    formState: { errors }
  } = useFormContext()

  switch (model.field_type) {
  case 'text':
    return (
      <FormTextField
        errors={errors?.[model.field_type] as FieldError}
        label={model.field_title}
        type={'text'}
        {...register(name, {
          required: model.required
        })}
      />
    )
  case 'textarea':
    return (
      <FormTextareaField
        errors={errors?.[model.field_type] as FieldError}
        label={model.field_title}
        {...register(name, {
          required: model.required
        })}
      />
    )
  case 'select':
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <FormSelectField
            errors={errors?.[model.field_type] as FieldError}
            label={model.field_title}
            options={model.field_values}
            {...field}
          />
        )}
      />
    )
  case 'media':
    return (
      <MediaSelect
        errors={errors?.[model.field_type] as FieldError}
        {...register(name, {
          required: model.required
        })}
      />
    )
  case 'link':
    return (
      <BlockLink
        errors={errors?.[model.field_type] as FieldError}
        {...register(name, {
          required: model.required
        })}
        {...model}
      />
    )
  case 'background':
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <FormSelectField
            errors={errors?.[model.field_type] as FieldError}
            label={model.field_title}
            options={blockFieldBackground.field_values}
            {...field}
          />
        )}
      />
    )
  case 'checkbox':
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <FormCheckboxField
            errors={errors?.[model.field_type] as FieldError}
            label={model.field_title}
            {...field}
          />
        )}
      />
    )
  default:
    // console.log(`missing implementation for field type ${model.field_type}`)
    return null
  }
}
