import React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { useRecoilState } from 'recoil'

import useMe from 'modules/state/me.hook'
import { sideMenuAtom } from 'modules/state/side-menu.atom'

import SideMenuDrawer from './side-menu-drawer'
const drawerWidth = 240

export default function SideMenu({ children }: React.PropsWithChildren) {
  const me = useMe()

  const [sideMenu, setSideMenu] = useRecoilState(sideMenuAtom)

  const toggleSideMenuVisibility = React.useCallback(() => {
    setSideMenu((prevState) => ({ ...prevState, isOpen: !prevState?.isOpen }) )
  }, [sideMenu])

  if (!me.isLoggedIn) {
    return (
      <Container
        maxWidth={'lg'}
        sx={{
          paddingTop: 'calc(var(--Header-height, 0) + 20px)'
        }}>
        {children}
      </Container>
    )
  }
  return (
    <Box sx={{ display: 'flex' }}>

      <Box
        component={'nav'}
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <SideMenuDrawer
          isOpen={sideMenu.isOpen}
          toggleVisibility={toggleSideMenuVisibility}
        />
      </Box>
      <Box
        component={'main'}
        sx={{
          flexGrow: 1,
          p: 3,
          width: { md: `calc(100% - ${drawerWidth}px)` },
          paddingTop: 'calc(var(--Header-height, 0) + 8px)'
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
