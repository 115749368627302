import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FieldError } from 'react-hook-form/dist/types/errors'
import { ChangeHandler } from 'react-hook-form/dist/types/form'
import { useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { v4 as uuidv4 } from 'uuid'

import rest from 'modules/rest'
import { Link } from 'types/link'

import BlockLinkItem from './block-link-item'

interface BlockLinkProps {
  errors?: FieldError
  multiple?: boolean
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  name: string;
  required?: boolean;
}

function BlockLink({ errors, ...restProps }: BlockLinkProps, ref: React.ForwardedRef<HTMLInputElement>) {
  const params = useParams()
  const [pages, setPages] = React.useState([])

  const {
    getValues
  } = useFormContext()

  const [links, setLinks] = React.useState<Link[]>([...getValues(restProps.name)])

  React.useEffect(() => {
    rest.pages.all({
      workspace: params.workspaceId,
      page_size: 1000
    }).then((response) => {
      setPages(response.results)
    })
  }, [])

  const addLink = React.useCallback(() => {
    setLinks((prevState) => [
      ...prevState,
      {
        id: uuidv4(),
        link: 'https://',
        page: pages[0],
        target: '_self',
        type: 'internal',
        title: ''
      }
    ])
  }, [pages])

  const onItemChange = React.useCallback((newVal: Link) => {
    setLinks((prevState) => prevState.map((item) => {
      if (item.id === newVal.id) {
        return newVal
      }
      return item
    }))
  },[])

  React.useEffect(() => {
    restProps.onChange({
      target: {
        value: links,
        name: restProps.name
      }
    })
  }, [links])

  return (
    <Stack gap={2}>
      {links.map((link) => (
        <BlockLinkItem
          key={link.id}
          link={link}
          onChange={onItemChange}
          pages={pages} />
      ))}
      <Stack direction={'row'}>
        <Button
          onClick={addLink}
          variant={'outlined'}>
          Add Link
        </Button>
      </Stack>
    </Stack>
  )
}

export default React.forwardRef(BlockLink)
