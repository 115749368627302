import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { handleErrors } from 'components/form/form.utils'
import { notify } from 'components/snackbar'
import Rest from 'modules/rest'
import { workspaceSelectedAtom } from 'modules/state/workspace.atom'

type APITokenCreateInputs = {
  title: string
  description: string
  allowed_origins: Array<string>
}

type APITokenCreateResponse = {
  id: string
}
export default function useWorkspaceAPITokenCreate() {
  const workspaceSelected = useRecoilValue(workspaceSelectedAtom)

  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)

  const {
    register,
    handleSubmit,
    formState,
    setError,
    control
  } = useForm<APITokenCreateInputs>()

  function create(data: APITokenCreateInputs) {

    if (!workspaceSelected) {
      return
    }
    setLoading(true)
    Rest.workspacesAccesses.post({
      ...data,
      workspace: workspaceSelected.id
    })
      .then(async (response: APITokenCreateResponse) => {
        notify('API Token created successfully.', 'success')

        navigate(`/workspaces/${workspaceSelected.id}/api-tokens/${response.id}`)

      }).catch((error) => {
        handleErrors<APITokenCreateInputs>(error, setError)
      }).finally(() => {
        setLoading(false)
      })
  }

  return {
    register: register,
    handleSubmit: handleSubmit(create),
    formState,
    loading,
    control
  }
}
