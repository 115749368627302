import React from 'react'
import WorkspacesPage from 'pages/workspaces'
import WorkspacesCreatePage from 'pages/workspaces/workspaces-create'
import WorkspacesDetailPage from 'pages/workspaces/workspaces-detail'
import WorkspaceApiTokensPage from 'pages/workspaces/workspaces-detail/workspace-api-tokens'
import WorkspaceApiTokensCreatePage
  from 'pages/workspaces/workspaces-detail/workspace-api-tokens/workspace-api-tokens-create'
import WorkspaceApiTokensDetailPage, { workspaceAPITokenLoader }
  from 'pages/workspaces/workspaces-detail/workspace-api-tokens/workspace-api-tokens-detail'
import WorkspaceApiTokensListPage
  from 'pages/workspaces/workspaces-detail/workspace-api-tokens/workspace-api-tokens-list'
import WorkspaceComponentsPage, { workspaceComponentsLoader } from 'pages/workspaces/workspaces-detail/workspace-components'
import WorkspaceDangerPage from 'pages/workspaces/workspaces-detail/workspace-danger'
import WorkspaceMediaPage from 'pages/workspaces/workspaces-detail/workspace-media'
import WorkspaceMembersPage from 'pages/workspaces/workspaces-detail/workspace-members'
import WorkspaceOverviewPage from 'pages/workspaces/workspaces-detail/workspace-overview'
import WorkspacePagesPage from 'pages/workspaces/workspaces-detail/workspace-pages'
import WorkspacePagesCreatePage from 'pages/workspaces/workspaces-detail/workspace-pages/workspace-pages-create'
import WorkspacePagesDetailPage, {
  workspacePageLoader
} from 'pages/workspaces/workspaces-detail/workspace-pages/workspace-pages-detail'
import WorkspacePagesListPage from 'pages/workspaces/workspaces-detail/workspace-pages/workspace-pages-list'
import WorkspaceSEOPage from 'pages/workspaces/workspaces-detail/workspace-seo'
import WorkspaceSettingsPage from 'pages/workspaces/workspaces-detail/workspace-settings'
import WorkspaceUsersPage from 'pages/workspaces/workspaces-detail/workspace-users'
import WorkspacesListPage from 'pages/workspaces/workspaces-list'

import RouteRedirect from 'components/route-redirect'

const workspaceRouter = {
  path: 'workspaces',
  element: (
    <RouteRedirect
      isAuthenticated={false}
      redirectTo={'/'}>
      <WorkspacesPage />
    </RouteRedirect>
  ),
  children: [
    {
      path: 'create',
      element: <WorkspacesCreatePage />
    },
    {
      path: ':workspaceId',
      element: <WorkspacesDetailPage />,
      children: [
        {
          path: '',
          element: <WorkspaceOverviewPage />
        },
        {
          path: 'pages',
          element: <WorkspacePagesPage />,
          children: [
            {
              path: '',
              element: <WorkspacePagesListPage />
            },
            {
              path: 'create',
              element: <WorkspacePagesCreatePage />
            },
            {
              path: ':pageId',
              Component: WorkspacePagesDetailPage,
              loader: workspacePageLoader
            }
          ]
        },
        {
          path: 'components',
          Component: WorkspaceComponentsPage,
          loader: workspaceComponentsLoader
        },
        {
          path: 'settings',
          element: <WorkspaceSettingsPage />
        },
        {
          path: 'members',
          element: <WorkspaceMembersPage />
        },
        {
          path: 'danger',
          element: <WorkspaceDangerPage />
        },
        {
          path: 'seo',
          element: <WorkspaceSEOPage />
        },
        {
          path: 'media',
          element: <WorkspaceMediaPage />
        },
        {
          path: 'users',
          element: <WorkspaceUsersPage />
        },
        {
          path: 'api-tokens',
          element: <WorkspaceApiTokensPage />,
          children: [
            {
              path: '',
              element: <WorkspaceApiTokensListPage />
            },
            {
              path: 'create',
              element: <WorkspaceApiTokensCreatePage />
            },
            {
              path: ':accessId',
              Component: WorkspaceApiTokensDetailPage,
              loader: workspaceAPITokenLoader
            }
          ]
        }
      ]
    },
    {
      path: '',
      element: <WorkspacesListPage />
    }
  ]
}

export default workspaceRouter
