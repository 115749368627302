import React from 'react'
import { Outlet } from 'react-router-dom'
import Stack from '@mui/material/Stack'

export default function SupportPage() {

  return (
    <Stack pb={5}>
      <Outlet />
    </Stack>
  )
}
