import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { handleErrors } from 'components/form/form.utils'
import { notify } from 'components/snackbar'
import Rest from 'modules/rest'
import useWorkspaces from 'modules/state/workspaces.hook'

type WorkspaceCreateInputs = {
  title: string
  description: string
  type: 'PORTFOLIO' | 'BLOG' | 'SHOP'
}

type WorkspaceCreateResponse = {
  id: string
}

export function useWorkspaceCreate() {
  const navigate = useNavigate()
  const { invalidate: workspaceInvalidate } = useWorkspaces()

  const [loading, setLoading] = React.useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<WorkspaceCreateInputs>()

  function create(data: WorkspaceCreateInputs) {
    setLoading(true)
    Rest.workspaces.post(data)
      .then(async (response: WorkspaceCreateResponse) => {
        notify('Workspace created successfully.', 'success')

        workspaceInvalidate().then(() => {
          navigate(`/workspaces/${response.id}`)
        })

      }).catch((error) => {
        handleErrors<WorkspaceCreateInputs>(error, setError)
      }).finally(() => {
        setLoading(false)
      })
  }

  return {
    register: register,
    handleSubmit: handleSubmit(create),
    formState: { errors },
    loading
  }
}
