import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import ModeToggle from '../mode-toggle'

import FooterLanguages from './footer-languages'
import FooterLinks from './footer-links'
import FooterNewsletter from './footer-newsletter'
import FooterSocial from './footer-social'

export default function Footer() {
  const { t } = useTranslation()
  const appName = process.env.REACT_APP_NAME

  const date = new Date()

  return (
    <Stack sx={{ zIndex: 1 }}>
      <Divider />
      <Container maxWidth={'lg'}>
        <Stack
          pt={4}
          alignItems={{
            xs: 'center',
            sm: 'initial'

          }}
          direction={{
            xs: 'column',
            sm: 'row'
          }}
        >
          <FooterNewsletter />

          <Stack
            direction={'row'}
            gap={{
              xs: 2,
              sm: 6,
              md: 10
            }}
            justifyContent={{
              xs: 'space-evenly',
              sm: 'flex-end'
            }}
            width={{
              xs: '100%',
              sm: '40%'
            }}>
            <FooterLanguages />

            <FooterLinks />
          </Stack>
        </Stack>
        <Stack py={2}>
          <Divider />
        </Stack>
        <Stack
          alignItems={'center'}
          direction={'row'}
          justifyContent={'space-between'}
          pb={2}>
          <Typography
            color={'textPrimary'}
            variant={'body2'}>
            {t('Copyright © {{0}} {{1}}.', { 0: appName , 1: date.getFullYear() })}
          </Typography>

          <ModeToggle />

          <FooterSocial />
        </Stack>
      </Container>
    </Stack>
  )
}
