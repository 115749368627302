import { Path } from 'react-hook-form'
import { UseFormSetError } from 'react-hook-form/dist/types/form'

import { notify } from 'components/snackbar'

type Errors = Record<string, unknown>

export function handleErrors<T extends Errors>(error: T, setError: UseFormSetError<T>): void {
  if (error?.message || error?.detail) {
    notify((error.message || error?.detail) as string, 'error')
  } else {
    Object.keys(error).forEach((key: string) => {
      const message = (error[key] as string[]).join(', ')
      setError(key as Path<T>, { type: 'api', message })
    })
  }
}
