import React from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid2'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

export default function WorkspacesListInitialCreate() {

  return (
    <Grid size={{ xs: 12 }}>
      <Typography >
        Start quickly and create a new workspace with ease.
      </Typography>
      <Button
        component={Link}
        href={'/workspaces/create'}>
        Create workspace
      </Button>
    </Grid>
  )
}
