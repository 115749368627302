import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { FormTextareaField, FormTextField } from 'components/form/form-field'

import { useWorkspaceCreate } from './workspaces-create.hook'

export default function WorkspacesCreatePage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    loading
  } = useWorkspaceCreate()

  return (
    <Container maxWidth={'sm'}>
      <Typography
        component={'h1'}
        variant={'h4'}>
        Create new Workspace
      </Typography>
      <form
        onSubmit={handleSubmit}
        noValidate>
        <Typography>
          <input
            {...register('type', { required: true })}
            type={'radio'}
            value={'PORTFOLIO'}
          />
          PORTFOLIO
        </Typography>
        <Typography>
          <input
            {...register('type', { required: true })}
            type={'radio'}
            value={'BLOG'}
          />
          BLOG
        </Typography>
        <Typography>
          <input
            {...register('type', { required: true })}
            type={'radio'}
            value={'SHOP'}
          />
          SHOP
        </Typography>
        <Stack
          gap={2}
          pt={4}>
          <FormTextField
            errors={errors.title}
            label={'Title'}
            {...register('title', { required: true })}
          />
          <FormTextareaField
            errors={errors.description}
            label={'Description'}
            rows={4}
            {...register('description')}
          />
        </Stack>
        <Stack
          py={2}>
          <LoadingButton
            loading={loading}
            type={'submit'}>
            Create
          </LoadingButton>
        </Stack>
      </form>
    </Container>
  )
}
