import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

import { Link } from 'types/link'
import { Page } from 'types/page'
import isEmpty from 'utils/isEmpty'

interface BlockLinkProps {
  onChange: (newValue: Link) => void
  link: Link
  pages: Pick<Page, 'id' | 'url_path' | 'title' | 'in_draft'>[]
}

const LINK_TARGETS = [
  '_self', '_blank'
]

const LINK_TYPE = [
  'internal', 'external'
]

function BlockLinkItem({ link, pages, onChange }: BlockLinkProps) {
  const [type, setType] = React.useState<Link['type']>(link.type)

  const handleChange = React.useCallback((newValue: unknown) => {
    onChange({
      ...link,
      ...newValue as Link
    })
  }, [link])

  if (isEmpty(pages)) {
    return null
  }

  return (
    <Stack
      direction={'row'}
      gap={1}>

      <TextField
        label={'Type'}
        value={type}
        onChange={(e) => {
          const newType = e.target.value as Link['type']
          setType(newType)
          handleChange({
            type: newType || ''
          })
        }}
        sx={{
          width: '30%'
        }}
        select
      >
        {LINK_TYPE.map((value) => (
          <MenuItem
            key={value}
            value={value}
          >
            {value}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        label={'Target'}
        value={link.target}
        onChange={(e) => {
          handleChange({
            target: e.target.value || ''
          })
        }}
        sx={{
          width: '30%'
        }}
        select
      >
        {LINK_TARGETS.map((value) => (
          <MenuItem
            key={value}
            value={value}
          >
            {value}
          </MenuItem>
        ))}

      </TextField>

      {type === 'external' && (
        <TextField
          label={'External link'}
          value={link.link}
          onChange={(e) => {
            handleChange({
              link: e.target.value || ''
            })
          }}
          fullWidth
        />
      )}

      {type === 'internal' && (
        <TextField
          label={'Page'}
          value={link.page?.id}
          onChange={(e) => {
            const page = pages.find((page) => page.id === e.target.value)
            if (page) {
              handleChange({
                page: {
                  id: page.id,
                  url_path: page.url_path,
                  title: page.title
                }
              })
            } else {
              handleChange({
                page: undefined
              })
            }
          }}
          fullWidth
          select
        >
          {pages.map((page) => (
            <MenuItem
              key={page.id}
              value={page.id}
            >
              {page.title}
            </MenuItem>
          ))}
        </TextField>
      )}

      <TextField
        label={'Title'}
        value={link.title}
        onChange={(e) => {
          handleChange({
            title: e.target.value || ''
          })
        }}
        fullWidth
      />

    </Stack>
  )
}

export default BlockLinkItem
