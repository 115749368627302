import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useRecoilState } from 'recoil'

import { notify } from 'components/snackbar'
import rest from 'modules/rest'
import { workspaceSelectedAtom } from 'modules/state/workspace.atom'
import useWorkspaces from 'modules/state/workspaces.hook'

export default function WorkspaceDangerPage() {
  const navigate = useNavigate()

  const [workspaceSelected] = useRecoilState(workspaceSelectedAtom)

  const { invalidate: workspaceInvalidate } = useWorkspaces()
  function deleteWorkspace() {
    if (!workspaceSelected?.id) {
      return
    }
    rest.workspaces.remove(workspaceSelected.id).then(() => {
      workspaceInvalidate().then(() => {
        notify('Workspace was deleted.', 'success')
        navigate('/workspaces')
      })

    })
  }

  return (
    <Stack>
      <Typography
        variant={'h4'}>
        Danger
      </Typography>
      <Typography color={'textSecondary'}>
        Review your intentions before actions, there is no coming back.
      </Typography>
      <Card
        color={'error'}
        sx={{ marginY: 2 }}>
        <Stack p={2}>
          <Typography variant={'h6'}>
          Delete workspace
          </Typography>
          <Typography color={'textSecondary'}>
            Are you sure you want to delete this workspace?
          </Typography>
          <Stack
            alignItems={'flex-end'}>
            <Button
              color={'error'}
              onClick={deleteWorkspace}
              variant={'contained'}>
              Delete
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  )
}
