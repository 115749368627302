import React, { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import Rest, { ListResponse } from 'modules/rest'
import { workspaceSelectedAtom } from 'modules/state/workspace.atom'
import { Page } from 'types/page'

export default function useWorkspacePages() {
  const workspaceSelected = useRecoilValue(workspaceSelectedAtom)

  const [response, setResponse] = React.useState<ListResponse<Page>>({
    results: [],
    count: 0,
    next: null,
    previous: null
  })

  const fetchPages = React.useCallback(async () => {
    try {
      const response: ListResponse<Page> = await Rest.pages.all({ workspace: workspaceSelected?.id })
      setResponse(response)
    } catch {

    }
  }, [workspaceSelected?.id])

  useEffect(() => {
    if (workspaceSelected?.id) {
      fetchPages()
    }
  }, [workspaceSelected?.id])

  function invalidate() {
    return fetchPages()
  }

  return {
    count: response?.count,
    pages: response?.results,
    invalidate
  }
}
