import React from 'react'
import { styled } from '@mui/material/styles'
import { MaterialDesignContent, SnackbarProvider } from 'notistack'

interface SnackbarComponentProps {
  children?: React.ReactNode;
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)<{variant: string}>((theme) => ({
  '&.notistack-MuiContent': {
    ...theme.theme.typography.body2,
    color: theme.theme.palette.text.primary,
    backgroundColor: theme.theme.palette.background.default,
    borderLeft: '5px solid transparent',
    padding: '6px 16px',
    '& svg': {
      marginInlineEnd: '16px !important',
      height: '1.5em !important',
      width: '1.5em !important'
    }
  },
  '&.notistack-MuiContent-error': {
    borderColor: theme.theme.palette.error.light,
    '& svg': {
      color: theme.theme.palette.error.main
    }
  },
  '&.notistack-MuiContent-success': {
    borderColor: theme.theme.palette.success.light,
    '& svg': {
      color: theme.theme.palette.success.main
    }
  },
  '&.notistack-MuiContent-warning': {
    borderColor: theme.theme.palette.warning.light,
    '& svg': {
      color: theme.theme.palette.warning.main
    }
  },
  '&.notistack-MuiContent-info': {
    borderColor: theme.theme.palette.info.light,
    '& svg': {
      color: theme.theme.palette.info.main
    }
  },
  '&.notistack-MuiContent-default': {

  }
}))

function SnackbarComponent({ children }: SnackbarComponentProps) {

  return (
    <SnackbarProvider
      autoHideDuration={5000}
      maxSnack={3}
      Components={{
        default: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent
      }}
      preventDuplicate
    >
      {children}
    </SnackbarProvider>
  )

}

export default SnackbarComponent
