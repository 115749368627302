import React from 'react'
import { FormProvider } from 'react-hook-form'
import LoadingButton from '@mui/lab/LoadingButton'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { ChevronDown } from 'lucide-react'

import { Block as BlockType } from 'types/block'
import { BlockContentModelField } from 'types/block-content-model'
import sort from 'utils/sort'

import useBlock from './block.hook'
import BlockField from './block-field'

interface BlockContentModelFieldItem extends BlockContentModelField {
  name: string
}

export default function Block(props: BlockType) {

  const { loading, handleSubmit, methods } = useBlock(props)

  const isDirty = methods.formState.isDirty
  const isValid = methods.formState.isValid
  const model = props?.block_content_model?.content_model || {}

  const blockFields: BlockContentModelFieldItem[] = Object.keys(model).map((name) => ({
    ...model[name],
    name
  })).sort(sort('order'))

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ChevronDown />}>
        <Typography>
          {props?.block_content_model.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit}
            noValidate>
            <Stack
              direction={'column'}
              gap={2}>

              {blockFields.map((model: BlockContentModelFieldItem) => (
                <BlockField
                  key={model.name}
                  loading={loading}
                  model={model}
                  name={model.name}
                />
              ))}
            </Stack>
            <Stack
              direction={'row'}
              py={2}>
              <LoadingButton
                disabled={!isDirty || !isValid}
                loading={loading}
                type={'submit'}
                variant={'contained'}>
                Save
              </LoadingButton>
            </Stack>
          </form>
        </FormProvider>
      </AccordionDetails>
    </Accordion>
  )
}
