import React from 'react'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'

import useWorkspaces from 'modules/state/workspaces.hook'
import isEmpty from 'utils/isEmpty'

import WorkspacesListInitialCreate from './workspaces-list-initial-create'
import WorkspacesListItem from './workspaces-list-item'

export default function WorkspacesListPage() {
  const { workspaces } = useWorkspaces()

  return (
    <Grid
      spacing={2}
      container>
      <Grid
        size={{ xs: 12 }}>
        <Typography variant={'h4'}>
          Workspaces
        </Typography>
      </Grid>
      {workspaces.map((workspace) => (
        <WorkspacesListItem
          key={workspace.id}
          workspace={workspace} />
      ))}
      {isEmpty(workspaces) && (
        <WorkspacesListInitialCreate />
      )}
    </Grid>
  )
}
