import React from 'react'
import Card from '@mui/material/Card'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { Page } from 'types/page'
import formatUser from 'utils/format-user'

export default function PageInfo(props: Page) {
  const [open, setOpen] = React.useState(false)

  return (
    <Stack gap={2}>
      <Link
        onClick={() => setOpen(!open)}>
        {open ? 'Hide Information' : 'Show Information'}
      </Link>

      {open && (
        <Card>
          <Stack p={2} >
            <Stack
              direction={'row'}
              gap={1}>
              <Typography>Created At:</Typography>
              <Typography color={'textSecondary'}>{ props.created_at }</Typography>
            </Stack>
            <Stack
              direction={'row'}
              gap={1}>
              <Typography>Created By:</Typography>
              <Typography color={'textSecondary'}>{ formatUser(props.created_by) }</Typography>
            </Stack>
            <Stack
              direction={'row'}
              gap={1}>
              <Typography>Last Update:</Typography>
              <Typography color={'textSecondary'}>{ props.last_update }</Typography>
            </Stack>
            <Stack
              direction={'row'}
              gap={1}>
              <Typography>Last Update By:</Typography>
              <Typography color={'textSecondary'}>{ formatUser(props.last_update_by) }</Typography>
            </Stack>
          </Stack>
        </Card>
      )}

    </Stack>
  )
}
