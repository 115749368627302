import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

export default function SupportPrivacyPage() {
  const { t } = useTranslation()
  const socialNetworks = [
    'Google',
    'Apple'
  ]
  const appName = process.env.REACT_APP_NAME
  return (
    <Container maxWidth={'md'}>
      <Typography
        component={'h1'}
        variant={'h3'}>
        {t('Privacy Policy')}
      </Typography>
      <Typography>
        {t('Effective Dec 23, 2024')}
      </Typography>
      <Typography>
        {t('This policy covers {{ 0 }}', { 0: appName })}
      </Typography>

      <Typography
        mb={1}
        mt={3}
        variant={'h5'}>
        {t('What information {{0}} collects and Why', { 0: appName })}
      </Typography>
      <Typography textAlign={'justify'}>
        {t('In order to give you the best possible experience using {{0}}, we collect information from your interactions with our network. We use common internet technologies, such as cookies and web server logs. We collect this basic information from everybody, whether they have an account or not.', { 0: appName })}
      </Typography>

      <Typography mt={2}>
        {t('The information we collect about all visitors to our website includes:')}
      </Typography>
      <ul>
        <Typography component={'li'}>
          {t('The visitor’s browser type')}
        </Typography>
        <Typography component={'li'}>
          {t('Referring site')}
        </Typography>
        <Typography component={'li'}>
          {t('The date and time of each visitor request')}
        </Typography>
        <Typography component={'li'}>
          {t('We also collect potentially personally-identifying information like Internet Protocol (IP) addresses.')}
        </Typography>
      </ul>
      <Typography mt={2}>
        {t('We use this information to:')}
      </Typography>
      <ul>
        <Typography component={'li'}>
          {t('Provide, test, improve, promote and personalize {{0}} Services', { 0: appName })}
        </Typography>
        <Typography component={'li'}>
          {t('Fight spam and other forms of abuse')}
        </Typography>
        <Typography component={'li'}>
          {t('Generate aggregate, non-identifying information about how people use {{0}} Services', { 0: appName })}
        </Typography>
      </ul>

      <Typography mt={2}>
        {t('In order for you to create an account on {{0}} and use our Services, we need to collect and process certain information. Depending on your use of the Services, that may include:', { 0: appName })}
      </Typography>
      <ul>
        <Typography component={'li'}>
          {t('Communications you send to us (for example, when you ask for support, send us questions or comments, or report a problem)')}
        </Typography>
        {socialNetworks.map((network) => (
          <Typography
            key={network}
            component={'li'}>
            {t('The email address associated with your {{1}} account, if you choose to sign up using your {{1}} credentials. {{0}} will also request permission to access additional information (these permissions are governed by {{1}}\'s privacy policies and can be managed through your {{1}} privacy settings). We never post anything to your {{1}} without your permission.', { 0: appName, 1: network })}
          </Typography>
        ))}
        <Typography component={'li'}>
          {t('You also have the option to give us more information if you want to, and this may include “User Personal Information.”')}
        </Typography>
      </ul>
      <Typography
        mb={1}
        mt={3}
        variant={'h5'}>
        {t('Information Disclosure')}
      </Typography>
      <Typography>
        {t('We do not share, sell, rent, or trade User Personal Information with third parties for commercial purposes.')}
      </Typography>
      <Typography mt={3}>
        {t('We do share certain aggregated, non-personally identifying information with others about how our users, collectively, use  {{0}}.', { 0: appName })}
      </Typography>
      <Typography mt={3}>
        {t('We do host first-party advertising on {{0}}. We do not run any code from advertisers and all ad images are hosted on managed {{0}} servers. For more details, see our section on Advertising Details.', { 0: appName })}
      </Typography>
      <Typography mt={3}>
        {t('We may use User Personal Information with your permission, so we can perform services you have authorized.')}
      </Typography>
      <Typography mt={3}>
        {t('We may share User Personal Information with a limited number of third party vendors who process it on our behalf to provide or improve our service, and who have agreed to privacy restrictions similar to our own Privacy Statement. Our third party vendors are listed below.')}
      </Typography>
      <Typography
        mb={1}
        mt={3}
        variant={'h5'}>
        {t('Advertising Details')}
      </Typography>
      <Typography>
        {t('We target advertisements based solely upon')}:
      </Typography>
      <ul>
        <Typography component={'li'}>
          {t('Details of the page where the advertisement is shown, including')}:
          <ul>
            <Typography component={'li'}>
              {t('The name and keywords associated with the page or article being viewed')}
              <ul>
                <Typography component={'li'}>
                  {t('We allow advertisers to target ads to a list of keywords advertising.')}
                </Typography>
              </ul>
            </Typography>
          </ul>
        </Typography>
      </ul>
      <Typography mt={3}>
        {t('We may place ads in')}:
      </Typography>
      <ul>
        <Typography component={'li'}>
          {t('Sidebars')}
        </Typography>
        <Typography component={'li'}>
          {t('Below articles')}
        </Typography>
        <Typography component={'li'}>
          {t('On search result pages')}
        </Typography>
        <Typography component={'li'}>
          {t('On tag pages')}
        </Typography>
      </ul>
      <Typography mt={3}>
        {t('All registered members have the ability to disable advertisements — where reasonable — through their Settings page. For instance, it\'s not feasible to disable certain advertisements in the form of recognition posts, site-wide contests, dedicated sponsorship page, etc.')}
      </Typography>
      <Typography
        mb={1}
        mt={3}
        variant={'h5'}>
        {t('Third Party Vendors')}
      </Typography>
      <Typography fontWeight={'bold'}>
        {t('We may share your account information with third parties in some circumstances, including:')}
      </Typography>
      <ul>
        <Typography component={'li'}>
          {t('with your consent')}
        </Typography>
        <Typography component={'li'}>
          {t('to a service provider or partner who meets our data protection standards')}
        </Typography>
        <Typography component={'li'}>
          {t('for survey or research purposes, after aggregation, anonymization, or pseudonymization')}
        </Typography>
        <Typography component={'li'}>
          {t('when we have a good faith belief it is required by law, such as pursuant to a subpoena or other legal process')}
        </Typography>
        <Typography component={'li'}>
          {t('when we have a good faith belief that doing so will help prevent imminent harm to someone')}
        </Typography>
      </ul>
      <Typography
        mb={1}
        mt={3}
        variant={'h5'}>
        {t('Data Storage')}
      </Typography>
      <Typography>
        {t('{{0}} uses third-party vendors and hosting partners for hardware, software, networking, storage, and related technology we need to run {{0}}. By using {{0}} Services, you authorize {{0}} to transfer, store, and use your information in the Serbia and any other country where we operate. All service providers and third-party vendors are required to meet our data protection standards.', { 0: appName })}
      </Typography>
      <Typography
        mb={1}
        mt={3}
        variant={'h5'}>
        {t('Site monitoring')}
      </Typography>
      <Typography>
        {t('{{0}} uses a variety of third-party services to diagnose errors and improve the performance of our site. We aim to minimize the amount of personal information shared, but the information may include your IP address or other identifying information. All service providers and third-party vendors are required to meet our data protection standards.', { 0: appName })}
      </Typography>
      <Typography
        mb={1}
        mt={3}
        variant={'h5'}>
        {t('Payment processing')}
      </Typography>

      <Typography>
        {t('{{0}} does not process payments directly — we rely on third-party services such as Stripe, Shopify, and Paypal to receive payments and store any payment information.', { 0: appName })}
      </Typography>
      <Typography
        mb={1}
        mt={3}
        variant={'h5'}>
        {t('Tracking & Cookies')}
      </Typography>
      <Typography>
        {t('We use browser cookies and similar technologies to recognize you when you return to our Services. Third-party vendors may also use cookies for various reasons.')}
      </Typography>
      <Typography mt={3}>
        {t('{{0}} uses a specific cookie in order to facilitate the use of Google Universal Analytics. If you are a Logged-In User, {{0}} may use your {{0}} user ID in combination with Google Universal Analytics and Google Analytics to track and analyze the pages of the Services you visit. We do this only to better understand how you use the Website and the other Services, with a view to offering improvements for all {{0}} users; and to tailor our business and marketing activities accordingly, both generally and specifically to you. Google Analytics cookies do not provide {{0}} with any Personal Information. You can prevent Google Analytics from recognizing you on return visits to this site by disabling cookies on your browser.', { 0: appName })}
      </Typography>
      <Typography mt={3}>
        {t('You may opt-out of this feature by installing the Google Analytics Opt-out Browser Add-on, by setting your web browser to refuse cookies, or by setting your browser to alert you when cookies are being sent. If you do so, note that some parts of the Site may not function properly.')}
      </Typography>
      <Typography
        mb={1}
        mt={3}
        variant={'h5'}>
        {t('Data Security')}
      </Typography>
      <Typography>
        {t('We use encryption (HTTPS/TLS) to protect data transmitted to and from our site. However, no data transmission over the Internet is 100% secure, so we can\'t guarantee security. You use the Service at your own risk, and you\'re responsible for taking reasonable measures to secure your account.')}
      </Typography>
      <Typography
        mb={1}
        mt={3}
        variant={'h5'}>
        {t('Administrative Emails from {{0}} ', { 0: appName })}
      </Typography>
      <Typography>
        {t('Sometimes we\'ll send you emails about your account, service changes or new policies. You can\'t opt out of this type of “transactional” email (unless you disable your account).')}
      </Typography>
      <Typography>
        {t('When you interact with a transactional email sent from {{0}} (such as opening an email or clicking on a particular link in an email), we may receive information about that interaction.', { 0: appName })}
      </Typography>
      <Typography
        mb={1}
        mt={3}
        variant={'h5'}>
        {t('Non-Administrative Emails from {{0}}', { 0: appName })}
      </Typography>
      <Typography>
        {t('Upon creating a {{0}} account, you will be opted into the {{0}} Newsletter and other non-administrative email. You can opt out of non-administrative emails such as digests, newsletters, and activity notifications through your account’s “Settings” page and at the link of the footer in any non-administrative email you receive from us.', { 0: appName })}
      </Typography>
      <Typography>
        {t('When you interact with a non-administrative email sent from {{0}} (such as opening an email or clicking on a particular link in an email), we may receive information about that interaction.', { 0: appName })}
      </Typography>
      <Typography
        mb={1}
        mt={3}
        variant={'h5'}>
        {t('Deleting Your Personal Information')}
      </Typography>
      <Typography>
        {t('You may request deletion of your personal information and account by emailing {{0}}.', { 0: process.env.REACT_APP_OFFICE_EMAIL })}
      </Typography>
      <Typography>
        {t('To protect information from accidental or malicious destruction, we may maintain residual copies for a brief time period. But, if you delete your account, your information and content will be unrecoverable after that time.')}
      </Typography>
      <Typography
        mb={1}
        mt={3}
        variant={'h5'}>
        {t('Data Portability')}
      </Typography>
      <Typography>
        {t('If you would like to request a copy of your user data, please email {{0}}.', { 0: process.env.REACT_APP_OFFICE_EMAIL })}
      </Typography>
      <Typography
        mb={1}
        mt={3}
        variant={'h5'}>
        {t('Business Transfers')}
      </Typography>
      <Typography>
        {t('If we are involved in a merger, acquisition, bankruptcy, reorganization or sale of assets such that your information would be transferred or become subject to a different privacy policy, we\'ll notify you in advance of any such change.')}
      </Typography>
      <Typography
        mb={1}
        mt={3}
        variant={'h5'}>
        {t('Changes to this Policy')}
      </Typography>
      <Typography>
        {t('We reserve the right to revise this Privacy Policy at any time. If we change this Privacy Policy in the future, we will post the revised Privacy Policy and update the “Effective Date, above, to reflect the date of the changes.')}
      </Typography>
      <Typography
        mb={1}
        mt={3}
        variant={'h5'}>
        {t('Questions')}
      </Typography>
      <Typography>
        {t('If you have any other questions write us on {{0}}.', { 0: process.env.REACT_APP_OFFICE_EMAIL })}
      </Typography>

    </Container>
  )
}
