import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { handleErrors } from 'components/form/form.utils'
import { notify } from 'components/snackbar'
import Rest from 'modules/rest'
import { workspaceSelectedAtom } from 'modules/state/workspace.atom'

type PageCreateInputs = {
  url_path: string
  title: string
}

type PageCreateResponse = {
  id: string
}
export default function useWorkspacePageCreate() {
  const workspaceSelected = useRecoilValue(workspaceSelectedAtom)

  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)

  const {
    register,
    handleSubmit,
    formState,
    setError
  } = useForm<PageCreateInputs>()

  function create(data: PageCreateInputs) {
    if (!workspaceSelected) {
      return
    }
    setLoading(true)
    Rest.pages.post({
      ...data,
      workspace: workspaceSelected.id
    })
      .then(async (response: PageCreateResponse) => {
        notify('Page created successfully.', 'success')

        navigate(`/workspaces/${workspaceSelected.id}/pages/${response.id}`)

      }).catch((error) => {
        handleErrors<PageCreateInputs>(error, setError)
      }).finally(() => {
        setLoading(false)
      })
  }

  return {
    register: register,
    handleSubmit: handleSubmit(create),
    formState,
    loading
  }
}
