import React from 'react'
import { Navigate } from 'react-router-dom'

import useMe from 'modules/state/me.hook'

interface RouteRedirectProps {
  isAuthenticated?: boolean;
  redirectTo?: string
}

function RouteRedirect({
  isAuthenticated = false,
  redirectTo = '/',
  children
}: React.PropsWithChildren<RouteRedirectProps> ): React.ReactElement {
  const me = useMe()

  // Redirect when a user is logged in and `isAuthenticated` is marked as true
  if (isAuthenticated && me.isLoggedIn) {
    return (
      <Navigate
        to={redirectTo}
        replace />
    )
  }

  // Redirect when a user is not logged in and `isAuthenticated` is marked as false
  if (!isAuthenticated && !me.isLoggedIn) {
    return (
      <Navigate
        to={redirectTo}
        replace />
    )
  }

  return (
    <>
      {children}
    </>
  )
}

export default RouteRedirect
