import React from 'react'
import { FieldError } from 'react-hook-form/dist/types/errors'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import { InputProps } from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Eye, EyeOff } from 'lucide-react'

import isEmpty from 'utils/isEmpty'
import omit from 'utils/omit'

import { FormFieldType } from './form-field.type'
import FormFieldHelp from './form-field-help.components'

interface FormPasswordFieldProps extends FormFieldType, Pick<TextFieldProps, 'variant'>, InputProps {
  errors?: FieldError
  noMeter?: boolean
  watch?: (key: string) => string
}

const WEAK_PASSWORD = /^((?=(.*[a-z])+)|(?=(.*[A-Z])+)|(?=(.*[0-9])+)).{8,}$/
const STRONG_PASSWORD = /^(?=(.*[a-z])+)(?=(.*[A-Z])+)(?=(.*[0-9])+)(?=(.*[/!@#$%^&*()\-_+.])+).{8,}$/
const VERY_STRONG_PASSWORD = /^(?=(.*[a-z]){2,})(?=(.*[A-Z]){2,})(?=(.*[0-9]){2,})(?=(.*[!@#$%^&*()\-_+.]){2,}).{14,}$/

function FormPasswordField({ watch, noMeter = false, ...inputRest }: FormPasswordFieldProps, ref: React.ForwardedRef<HTMLInputElement>) {
  const [visible, setVisible] = React.useState(false)

  let passwordRating = 0
  let value = ''
  if (!noMeter && inputRest.name && watch) {
    value = watch(inputRest.name)
    if (value) {
      if (value.match(WEAK_PASSWORD)) {
        passwordRating = 33
      }
      if (value.match(STRONG_PASSWORD)) {
        passwordRating = 66
      }
      if (value.match(VERY_STRONG_PASSWORD)) {
        passwordRating = 100
      }
    }
  }

  const hasErrors = !isEmpty(inputRest.errors)

  return (
    <FormControl
      error={hasErrors}
      sx={{
        '--hue': Math.min(passwordRating * 1.2, 120)
      }}
    >
      <TextField
        ref={ref}
        error={hasErrors}
        type={visible ? 'text' : 'password'}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment
                position={'end'}>
                <IconButton onClick={() => setVisible(!visible)}>
                  {visible ? <Eye /> : <EyeOff />}
                </IconButton>
              </InputAdornment>
            )
          }
        }}
        {...omit(inputRest, ['watch'])}
      />
      {!noMeter && value && (
        <Stack px={0.4}>
          <LinearProgress
            value={passwordRating}
            variant={'determinate'}
            sx={{
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor: 'hsl(var(--hue) 80% 40%)'
              }
            }}
          />
          <Typography
            sx={{ alignSelf: 'flex-end', color: 'hsl(var(--hue) 80% 30%)' }}
          >
            {passwordRating < 33 && 'Very weak'}
            {passwordRating >= 33 && passwordRating < 66 && 'Weak'}
            {passwordRating >= 66 && passwordRating < 100 && 'Strong'}
            {passwordRating === 100 && 'Very strong'}
          </Typography>
        </Stack>
      )}
      <FormFieldHelp {...inputRest} />
    </FormControl>
  )
}

export default React.forwardRef(FormPasswordField)
