import React from 'react'
import Typography from '@mui/material/Typography'

export default function ProfilePasswordPage() {

  return (
    <Typography>
        profile-password
    </Typography>
  )
}
