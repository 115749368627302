import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export default function SupportHelpPage() {
  const { t } = useTranslation()

  return (
    <Container maxWidth={'md'}>
      <Typography
        component={'h1'}
        mb={3}
        variant={'h3'}>
        {t('Help')}
      </Typography>

      <Stack>
        <Typography
          mb={1}
          variant={'h5'}>
          {t('How to create account')}
        </Typography>

        <Typography >
          {t('Visit our page to create account.')}
        </Typography>

        <Typography >
          {t('Be aware that after account is created, verification email will be sent to your email.')}
        </Typography>

        <Typography >
          {t('To verify account navigate to link that you will find in email.')}
        </Typography>

        <Link href={'/auth/register'}>
          {t('Register')}
        </Link>
      </Stack>

    </Container>
  )
}
