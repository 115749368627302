import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import RootPage from 'pages'
import { RecoilRoot } from 'recoil'
import rootRouter from 'routes'

import Snackbar from 'components/snackbar/snackbar'
import Theme from 'modules/theme'

import './index.css'
import '@fontsource/nunito/300.css'
import '@fontsource/nunito/400.css'
import '@fontsource/nunito/500.css'
import '@fontsource/nunito/700.css'

import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootPage/>,
    children: rootRouter
  }
])

root.render(
  // <React.StrictMode>
  <RecoilRoot>
    <Theme>
      <Snackbar>
        <RouterProvider router={router} />
      </Snackbar>
    </Theme>
  </RecoilRoot>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
