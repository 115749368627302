import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

export default function SupportAboutPage() {
  const { t } = useTranslation()

  return (
    <Container maxWidth={'md'}>
      <Typography
        component={'h1'}
        mb={5}
        variant={'h3'}>
        {t('About us')}
      </Typography>

    </Container>
  )
}
