import React from 'react'
import Typography from '@mui/material/Typography'
import { useRecoilValue } from 'recoil'

import { workspaceSelectedAtom } from 'modules/state/workspace.atom'

export default function WorkspaceMembersPage() {
  const workspaceSelected = useRecoilValue(workspaceSelectedAtom)

  return (
    <Typography>
      Workspace members
      <br />
      {workspaceSelected?.title}
    </Typography>
  )
}
