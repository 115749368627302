import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { FormTextField } from 'components/form/form-field'
import useMe from 'modules/state/me.hook'

import { useProfileOverview } from './profile-overview.hook'

export default function ProfileOverviewPage() {
  const me = useMe()

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    loading
  } = useProfileOverview()

  return (
    <Stack>
      <Typography variant={'h4'}>
        Profile
      </Typography>

      <Stack
        gap={2}
        pt={4}
        direction={{
          sm: 'column',
          md: 'row'
        }}
      >
        <Stack
          maxWidth={{
            xs: '100%',
            md: '500px'
          }}
          width={{
            xs: '100%',
            md: '50%'
          }}
        >
          <Card>
            <Stack p={2}>
              <Typography variant={'h5'}>
              Basic information
              </Typography>
              <Divider
                sx={{
                  my: 2
                }} />
              <form onSubmit={handleSubmit}>
                <Stack
                  gap={2}
                >
                  <FormTextField
                    color={'primary'}
                    field={errors.name}
                    label={'Name'}
                    variant={'standard'}
                    {...register('name', { required: true })}
                  />
                  <FormTextField
                    color={'primary'}
                    field={errors.email}
                    help={'Contact support for email change'}
                    label={'Email'}
                    type={'email'}
                    variant={'standard'}
                    disabled
                    {...register('email', { required: true })}
                  />
                  <Stack
                    alignItems={'flex-start'}
                    pt={4}>
                    <LoadingButton
                      disabled={!isDirty}
                      loading={loading}
                      type={'submit'}
                      variant={'contained'}>
                      Update
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            </Stack>
          </Card>
        </Stack>
        <Stack
          maxWidth={{
            xs: '100%',
            md: '400px'
          }}
          width={{
            xs: '100%',
            md: '50%'
          }}
        >
          <Card>
            <Stack p={2}>
              <Typography variant={'h5'}>
                Status
              </Typography>
              <Divider
                sx={{
                  my: 2
                }}/>
              <Stack gap={2}>
                <Stack
                  direction={'row'}
                  gap={2}>
                  <Typography>Date joined:</Typography>
                  <Typography color={'textSecondary'}>{
                    me.date_joined
                  }</Typography>
                </Stack>
                {me?.is_admin && (
                  <Stack
                    direction={'row'}
                    gap={2}>
                    <Typography>Is Admin</Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Stack>

    </Stack>
  )
}
