import React from 'react'
import Typography from '@mui/material/Typography'

export default function ProfileBillingPage() {

  return (
    <Typography>
      Profile billing
    </Typography>
  )
}
