import config from './config'

function changeHeightWidth(
  height: number,
  width: number
) {
  if (width > config.maxWidth) {
    height = Math.round((height * config.maxWidth) / width)
    width = config.maxWidth
  }
  if (height > config.maxHeight) {
    width = Math.round((width * config.maxHeight) / height)
    height = config.maxHeight
  }
  // TODO: check for min size
  // if (config.maxWidth && width < config.maxWidth) {
  //   throw new Error(`Image can not be smaller than ${ config.maxWidth } x ${ config.maxHeight }`)
  // }
  // if (config.maxHeight && height < config.maxHeight) {
  //   throw new Error(`Image can not be smaller than ${ config.maxWidth } x ${ config.maxHeight }`)
  // }
  return { height, width }
}

export default changeHeightWidth
