import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export default function FooterLanguages() {
  const { t, i18n } = useTranslation()

  const languages: Record<string, string> = {
    'sr-Cyrl': 'Српски',
    sr: 'Srpski',
    en: 'English',
    nl: 'Nederlands',
    de: 'Deutsch'
  }

  return (
    <Stack
      gap={0.5}>
      <Typography
        color={'textPrimary'}
        variant={'body1'}>
        {t('Language')}
      </Typography>
      {Object.keys(languages).map((key: string) => (
        <Link
          key={`i18n-${key}`}
          color={key === i18n.language ? 'textDisabled' : 'primary'}
          onClick={() => i18n.changeLanguage(key)}
        >
          {languages[key]}
        </Link>
      ))}
    </Stack>
  )
}
