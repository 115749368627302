import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'

import cookie from 'modules/cookie'
import Rest, { ListResponse } from 'modules/rest'
import { User } from 'types/user'
import isEmpty from 'utils/isEmpty'

import { userAtom } from './user.atom'
import { Invalidate } from '.'

type Auth = User & Invalidate<User> & {
  logout: () => void
  isLoggedIn: boolean
}

export default function useMe(onMount = true): Auth {

  const [me, setMe] = useRecoilState(userAtom)

  const fetchMe = React.useCallback(async (data?: User): Promise<User | null> => {
    const token = cookie.get('token')

    if (!isEmpty(data)) {
      setMe({
        ...me,
        ...data
      })
    }

    if (token) {
      if (!isEmpty(me)) {
        return me as User
      }
      try {
        const response: ListResponse<User> = await Rest.users.all()
        const loggedInUser = response.results[0]

        setMe(loggedInUser)
        return loggedInUser
      } catch {
        logout()
      }
    }

    logout()

    return null
  }, [])

  useEffect(() => {
    if (onMount) {
      fetchMe()
    }

  }, [onMount])

  function invalidate(data?: User) {
    return fetchMe(data)
  }

  function logout() {
    cookie.remove('token')
    setMe({})
  }

  return {
    ...(me as User),
    isLoggedIn: !isEmpty(me),
    invalidate,
    logout
  }
}
