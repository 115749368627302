import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { handleErrors } from 'components/form/form.utils'
import { notify } from 'components/snackbar'
import Rest from 'modules/rest'
import { Block } from 'types/block'

type BlockInputs = {

}

type BlockResponse = Block

export default function useBlock(block: Block) {
  const params = useParams()

  const blockModel = block?.block_content_model?.content_model || {}
  const blockModelType = block?.block_content_model?.content_model_type || 'PAGE'

  let initialValues: Record<string, unknown> = {}
  Object.keys(blockModel).forEach((name: string) => {
    if (blockModel[name].field_type === 'checkbox') {
      initialValues[name] = false
    } else if (blockModel[name].field_type === 'background') {
      initialValues[name] = 'light'
    } else {
      initialValues[name] = ''
    }

  })

  const methods = useForm<BlockInputs>({
    defaultValues: {
      ...initialValues,
      ...block.data
    }
  })

  async function save(data: BlockInputs) {
    let promise
    if (blockModelType === 'PAGE') {
      if (block?.id) {
        promise = Rest.blocks.patch(block.id, { data, order: block.order })
      } else {
        promise = Rest.blocks.post({
          data,
          page: params.pageId,
          order: block.order,
          block_content_model: block.block_content_model.id
        })
      }
    } else if (blockModelType === 'COMPONENT') {
      if (block?.id) {
        promise = Rest.components.patch(block.id, { data })
      } else {
        promise = Rest.components.post({
          data,
          workspace: params.workspaceId,
          block_content_model: block.block_content_model.id
        })
      }
    } else {
      throw new Error('Unknown block type')
    }

    return promise.then((response: BlockResponse) => {
      notify('Saved', 'success')
      methods.reset({
        ...initialValues,
        ...response.data
      })
    }).catch((error) => {
      handleErrors<BlockInputs>(error, methods.setError)
    })
  }

  return {
    methods,
    handleSubmit: methods.handleSubmit(save),
    loading: methods.formState.isSubmitting
  }
}
