import React from 'react'
import { Boxes, CreditCard, Lock, Settings, User } from 'lucide-react'

import SideMenuDrawerItem from './side-menu-drawer-item'

const profileItems: Array<[string, React.ReactElement]> = [
  [
    'workspaces',
    <SideMenuDrawerItem
      href={'/workspaces'}
      icon={<Boxes />}
      text={'Workspaces'} />
  ],
  [
    'profile',
    <SideMenuDrawerItem
      href={'/profile'}
      icon={<User />}
      text={'Profile'} />
  ],
  [
    'profile-password',
    <SideMenuDrawerItem
      href={'/profile/password'}
      icon={<Lock />}
      text={'Password'} />
  ],
  [
    'profile-billing',
    <SideMenuDrawerItem
      href={'/profile/billing'}
      icon={<CreditCard />}
      text={'Billing'} />
  ],
  [
    'profile-settings',
    <SideMenuDrawerItem
      href={'/profile/settings'}
      icon={<Settings />}
      text={'Settings'} />
  ]
]

export default profileItems
