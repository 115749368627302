import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import useMe from 'modules/state/me.hook'
import { workspaceSelectedAtom } from 'modules/state/workspace.atom'
import useWorkspaces from 'modules/state/workspaces.hook'
import isEmpty from 'utils/isEmpty'

function useWorkspaceSelectedObserver() {
  const { pathname } = useLocation()

  const [workspaceSelected, setWorkspaceSelected] = useRecoilState(workspaceSelectedAtom)

  const me = useMe(false)
  const { workspaces, invalidate: workspaceInvalidate } = useWorkspaces()

  React.useEffect(() => {
    if (isEmpty(workspaces)) {
      setWorkspaceSelected(undefined)
      return
    }

    if (
      ['/workspaces', '/workspaces/create'].includes(pathname)
      || pathname.match('/profile|/support')
    ) {
      setWorkspaceSelected(undefined)
      return
    }

    const workspaceMatch = pathname.match('/workspaces/([\\w|\\d|-]*)')
    if (workspaceMatch) {
      const workspaceId = workspaceMatch[1]
      if (!workspaceSelected || workspaceSelected?.id !== workspaceId) {
        const workspace = workspaces.find((workspace) => workspace.id === workspaceId)
        setWorkspaceSelected(workspace)
      }
    }

  }, [pathname, workspaces])

  useEffect(() => {
    if (me?.id) {
      workspaceInvalidate()
    }

  }, [me?.id])

}

export default useWorkspaceSelectedObserver
