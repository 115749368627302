import LoadingButton from '@mui/lab/LoadingButton'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { FormPasswordField, FormTextField } from 'components/form/form-field'

import SocialLogin from '../components/social-login'

import { useLogin } from './login.hook'

export default function LoginPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    loading
  } = useLogin()

  return (
    <>
      <Typography
        component={'h1'}
        variant={'h4'}>
          Sign In
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack
          gap={2}
          pt={4}>
          <FormTextField
            color={'primary'}
            field={errors.email}
            label={'Email'}
            type={'email'}
            {...register('email', { required: true })}
          />
          <FormPasswordField
            field={errors.password}
            label={'Password'}
            noMeter
            {...register('password', { required: true })}
          />
          <LoadingButton
            loading={loading}
            type={'submit'}
            variant={'contained'}>
            Login
          </LoadingButton>
        </Stack>

        <Stack
          alignItems={'center'}
          py={2}
        >
          <Link
            href={'forgot-password'}>
           Forgot Password?
          </Link>
        </Stack>
      </form>
      <Divider
        sx={{ py: 2 }}>
        <Typography
          variant={'body1'}>
          or
        </Typography>
      </Divider>

      <SocialLogin />

      <Stack
        alignItems={'center'}
        direction={'row'}
        justifyContent={'center'}
        pt={3}
      >
        <Typography variant={'body2'}>
          Do not have an account?
        </Typography>
        &nbsp;
        <Typography
          color={'primary'}
          component={Link}
          href={'register'}>
            Sign up
        </Typography>
      </Stack>
    </>
  )
}
