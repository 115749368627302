import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FieldError } from 'react-hook-form/dist/types/errors'
import { ChangeHandler, RefCallBack } from 'react-hook-form/dist/types/form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar, { imageListItemBarClasses } from '@mui/material/ImageListItemBar'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Trash, X } from 'lucide-react'

import FormFieldHelp from 'components/form/form-field/form-field-help.components'
import { Media } from 'types/media'
import isEmpty from 'utils/isEmpty'

import MediaList from '../media-list'

interface MediaSelectProps {
  errors?: FieldError
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  ref: RefCallBack;
  name: string;
  min?: string | number;
  max?: string | number;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  required?: boolean;
  disabled?: boolean;
}

function MediaSelect({ errors, ...restProps }:MediaSelectProps, ref: React.ForwardedRef<HTMLInputElement>) {
  const {
    getValues
  } = useFormContext()
  const hasErrors = !isEmpty(errors)
  const [open, setOpen] = React.useState(false)

  const [addedMedia, setAddedMedia] = React.useState<Media[]>([...getValues(restProps.name)])

  const handleClickOpen = React.useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = React.useCallback(() => {
    setOpen(false)
  }, [])

  const onMediaAdd = React.useCallback((item: Media) => {
    setAddedMedia((prevState) => ([...prevState, item]))
  }, [])

  const removeMedia = React.useCallback((itemToRemove: Media) => {
    setAddedMedia((prevState) => prevState.filter((item) => item.id !== itemToRemove.id))
  }, [])

  React.useEffect(() => {
    restProps.onChange({
      target: {
        value: addedMedia.map(({ id, file }) => ({ id, file })),
        name: restProps.name
      }
    })
  }, [addedMedia])

  return (
    <FormControl
      error={hasErrors}>

      <Stack direction={'row'}>

        <Button
          onClick={handleClickOpen}
          variant={'outlined'}>
          Select media
        </Button>
      </Stack>
      <ImageList
        cols={6}
        rowHeight={250}
        variant={'quilted'}
      >
        {isEmpty(addedMedia) && (
          <Typography variant={'caption'}>
          No media selected
          </Typography>
        )}
        {addedMedia.map((item) => (
          <ImageListItem
            key={item.id}
            sx={{
              [`&:hover .${imageListItemBarClasses.root}`]: {
                visibility: 'visible'
              }
            }} >
            <img
              alt={item.id}
              loading={'lazy'}
              src={`${process.env.REACT_APP_MEDIA_ROOT}${item.file}`}
              srcSet={`${process.env.REACT_APP_MEDIA_ROOT}${item.file}`}
            />
            <ImageListItemBar
              position={'top'}
              actionIcon={
                <IconButton onClick={() => removeMedia(item)}>
                  <Trash size={18}/>
                </IconButton>
              }
              sx={{
                p: 0.5
              }}
            />
          </ImageListItem>
        )
        )}
      </ImageList>
      <FormFieldHelp {...restProps} />
      <Dialog
        aria-labelledby={'customized-dialog-title'}
        maxWidth={'lg'}
        onClose={handleClose}
        open={open}
      >
        <DialogTitle
          id={'customized-dialog-title'}
          sx={{ m: 0, p: 2 }}>
          Media
        </DialogTitle>
        <IconButton
          aria-label={'close'}
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <X />
        </IconButton>
        <DialogContent dividers>
          <MediaList
            onChange={onMediaAdd}
            selectedMedia={addedMedia} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </FormControl>
  )
}

export default React.forwardRef(MediaSelect)
