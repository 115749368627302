import React from 'react'
import { useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar, { imageListItemBarClasses } from '@mui/material/ImageListItemBar'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Trash } from 'lucide-react'

import { notify } from 'components/snackbar'
import rest from 'modules/rest'
import { Media } from 'types/media'

import MediaUpload from './media-upload'

export default function WorkspaceMediaPage() {
  const params = useParams()
  const page_size = 15

  const [deletedMediaIds, setDeletedMediaIds] = React.useState<string[]>([])

  const [page, setPage] = React.useState<{
    currentPage: number
    count: number
    results: Media[]
  }>({
    currentPage: 1,
    count: 0,
    results: []
  })

  const loadMedia = React.useCallback((page: number) => {
    rest.media.all({ workspace: params.workspaceId, page, page_size }).then((result) => {

      setPage((prevState) => ({
        currentPage: page,
        count: result.count,
        results: [
          ...prevState.results,
          ...result.results
        ]
      }))
    })
  }, [page])

  const refreshMediaList = React.useCallback(() => {
    loadMedia(1)
  }, [])

  React.useEffect(() => {
    refreshMediaList()
    return () => {
      setPage({
        currentPage: 1,
        count: 0,
        results: []
      })
    }
  }, [])

  const deleteMedia = React.useCallback((itemId: string) => {
    rest.media.remove(itemId, { workspace: params.workspaceId }).then(() => {
      notify('Deleted.', 'success')

      setDeletedMediaIds((prevState) => ([
        ...prevState,
        itemId
      ]))
    })

  }, [])

  return (
    <Stack>
      <Typography
        variant={'h4'}>
        Media
      </Typography>
      <Typography color={'textSecondary'}>
        List of all media items that are in this workspace.
      </Typography>
      <MediaUpload refreshMediaList={refreshMediaList}/>

      <ImageList
        cols={5}
        rowHeight={300}
        variant={'quilted'}
      >
        {page.results.map((item) => (
          !deletedMediaIds.includes(item.id) && (
            <ImageListItem
              key={item.id}
              sx={{
                [`&:hover .${imageListItemBarClasses.root}`]: {
                  visibility: 'visible'
                }
              }} >
              <img
                alt={item.id}
                loading={'lazy'}
                src={`${process.env.REACT_APP_MEDIA_ROOT}${item.file}`}
                srcSet={`${process.env.REACT_APP_MEDIA_ROOT}${item.file}`}
              />
              <ImageListItemBar
                position={'top'}
                actionIcon={
                  <IconButton onClick={() => deleteMedia(item.id)}>
                    <Trash size={18}/>
                  </IconButton>
                }
                sx={{
                  p: 0.5
                }}
              />
            </ImageListItem>
          )
        ))}
      </ImageList>

      {(page.currentPage * page_size < page.count) && (
        <Button onClick={() => loadMedia(page.currentPage + 1)}>
        Load more
        </Button>
      )}
    </Stack>
  )
}
