import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { FormTextField } from 'components/form/form-field'

import useWorkspacePageCreate from './workspace-pages-create.hook'

export default function WorkspacePagesCreatePage() {

  const {
    register,
    handleSubmit,
    formState: { errors },
    loading
  } = useWorkspacePageCreate()

  return (
    <Stack>
      <Typography
        variant={'h4'}>
        Create Page
      </Typography>
      <form
        onSubmit={handleSubmit}
        noValidate>

        <Stack
          gap={2}
          pt={4}
          width={{
            xs: '100%',
            md: '50%'
          }}>
          <FormTextField
            errors={errors.title}
            label={'Title'}
            {...register('title', { required: true })}
          />
          <FormTextField
            errors={errors.url_path}
            label={'URL path'}
            {...register('url_path', { required: true })}
          />
          <Stack
            alignItems={'flex-end'}
            py={2}
          >
            <LoadingButton
              loading={loading}
              type={'submit'}
              variant={'outlined'}>
              Create
            </LoadingButton>
          </Stack>
        </Stack>

      </form>
    </Stack>
  )
}
