import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import MuiListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent, selectClasses } from '@mui/material/Select'
import { styled } from '@mui/material/styles'
import { LayoutGrid } from 'lucide-react'
import { useRecoilValue } from 'recoil'

import WorkspaceIcon from 'components/workspace-icon'
import { workspaceSelectedAtom } from 'modules/state/workspace.atom'
import useWorkspaces from 'modules/state/workspaces.hook'
import isEmpty from 'utils/isEmpty'

const ListItemAvatar = styled(MuiListItemAvatar)({
  minWidth: 0,
  marginRight: 12
})

export default function SideMenuWorkspaceSelect() {
  const navigate = useNavigate()
  const workspaceSelected = useRecoilValue(workspaceSelectedAtom)

  const { workspaces } = useWorkspaces()

  function handleChange(event: SelectChangeEvent){
    navigate(
      ['/workspaces', event.target.value].filter((item) => item).join('/')
    )
  }

  if (isEmpty(workspaceSelected)) {
    return null
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          p: 1.5
        }}
      >
        <Select
          id={'workspace-select'}
          inputProps={{ 'aria-label': 'Select workspace' }}
          labelId={'workspace-select'}
          onChange={handleChange}
          value={workspaceSelected?.id}
          sx={{
            maxHeight: 56,
            width: 215,
            '&.MuiList-root': {
              p: '8px'
            },
            [`& .${selectClasses.select}`]: {
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              pl: 1
            }
          }}
          displayEmpty
          fullWidth
        >
          <ListSubheader sx={{ pt: 0 }}>Workspaces</ListSubheader>
          {workspaces.map((workspace) => (
            <MenuItem
              key={workspace.id}
              value={workspace.id}
            >
              <ListItemAvatar>
                <WorkspaceIcon type={workspace.type} />
              </ListItemAvatar>
              <ListItemText
                primary={workspace.title}
                secondary={workspace.type}
              />
            </MenuItem>
          ))}
          <Divider sx={{ mx: -1 }} />
          <MenuItem value={'create'}>
            <ListItemIcon>
              <LayoutGrid />
            </ListItemIcon>
            <ListItemText
              primary={'Create new Workspaces'}
              secondary={'Create new workspace'} />
          </MenuItem>
        </Select>
      </Box>
      <Divider />
    </>
  )
}
