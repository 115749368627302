import Methods from './methods'

const Rest = {
  systemPing: new Methods( '/api/system/ping/'),

  auth: new Methods( '/api/auth/'),

  register: new Methods( '/api/auth/register/'),
  activation: new Methods('/api/auth/activation'),
  requestAccount: new Methods('/api/auth/request-account'),

  resetPassword: new Methods('/api/auth/reset_password'),
  resetPasswordSet: new Methods('/api/auth/reset_password/set'),

  users: new Methods('/api/users/'),
  passwordChange: new Methods('/api/users/:id/password/'),

  workspaces: new Methods('/api/workspaces/'),
  workspacesAccesses: new Methods('/api/accesses/'),
  workspacesFeatures: new Methods('/api/features/'),

  pages: new Methods('/api/pages/'),

  blockContentModels: new Methods('/api/block-content-models/'),
  blocks: new Methods('/api/blocks/'),

  components: new Methods('/api/components/'),

  tags: new Methods('/api/tags/'),
  media: new Methods('/api/media/'),

  errors: new Methods('/api/errors/'),

  ping: new Methods('/api/system/ping/')
}

export default Rest
