import { BriefcaseBusiness, Rss, Store } from 'lucide-react'

import { Workspace } from 'types/workspace'

type WorkspaceIconProps = Pick<Workspace, 'type'>

function WorkspaceIcon({ type }: WorkspaceIconProps) {

  switch (type) {
  case 'SHOP':
    return <Store />
  case 'BLOG':
    return <Rss />
  case 'PORTFOLIO':
    return <BriefcaseBusiness />
  default:
    return null
  }
}

export default WorkspaceIcon
