import rest from 'modules/rest'
import { Block } from 'types/block'
import { BlockContentModel } from 'types/block-content-model'

export async function workspaceComponentsLoader({ params: { workspaceId } }: {params: {workspaceId: string}}) {
  const [components, blockContentModels] = await Promise.all([
    rest.components.all({ workspace: workspaceId }),
    rest.blockContentModels.all({ content_model_type: 'COMPONENT' })
  ])

  const blockContentModelDict: Record<string, BlockContentModel> = {}
  blockContentModels.forEach((model: BlockContentModel) => {
    blockContentModelDict[model.id] = model
  })

  const componentsDict: Record<string, Block> = {}
  components.forEach((component: Block) => {
    componentsDict[component.block_content_model.id] = component
  })

  const retVal: Block[] = []
  Object.keys(blockContentModelDict).forEach((blockContentModelId) => {
    const componentItem = componentsDict[blockContentModelId]

    if (componentItem) {
      retVal.push(componentItem)
    } else {
      const model = blockContentModelDict[blockContentModelId]
      retVal.push({
        block_content_model: model,
        data: {}
      })
    }
  })

  return retVal

}

export { default } from './workspace-components.page'
