import React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { ChevronDown } from 'lucide-react'

import { BlockContentModel } from 'types/block-content-model'

import { useBlockContentModels } from '../block-content-model'

interface AddPageBlockProps {
  addInitialBlock: (blockContentModel: BlockContentModel) => void
}

export default function AddPageBlock({ addInitialBlock }: AddPageBlockProps) {

  const blockContentModels = useBlockContentModels()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const handleSelect = React.useCallback((blockContentModel: BlockContentModel) => () => {
    addInitialBlock(blockContentModel)
    handleClose()
  }, [])

  return (
    <div>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup={'true'}
        endIcon={<ChevronDown />}
        id={'basic-button'}
        onClick={handleClick}
        variant={'contained'}
      >
        Add Block
      </Button>
      <Menu
        anchorEl={anchorEl}
        id={'basic-menu'}
        onClose={handleClose}
        open={open}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {blockContentModels?.map((blockModel) => (
          <MenuItem
            key={blockModel.name}
            onClick={handleSelect(blockModel)}
          >
            {blockModel.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

