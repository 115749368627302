import React, { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import Rest, { ListResponse } from 'modules/rest'
import { workspaceSelectedAtom } from 'modules/state/workspace.atom'
import { WorkspaceAccess } from 'types/workspace'

export default function useWorkspaceAPITokens() {
  const workspaceSelected = useRecoilValue(workspaceSelectedAtom)

  const [response, setResponse] = React.useState<ListResponse<WorkspaceAccess>>({
    results: [],
    count: 0,
    next: null,
    previous: null
  })

  const fetchData = React.useCallback(async () => {
    try {
      const response: ListResponse<WorkspaceAccess> = await Rest.workspacesAccesses.all({ workspace: workspaceSelected?.id })
      setResponse(response)
    } catch {

    }
  }, [workspaceSelected?.id])

  useEffect(() => {
    if (workspaceSelected?.id) {
      fetchData()
    }
  }, [workspaceSelected?.id])

  return response
}
