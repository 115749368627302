import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import LoadingButton from '@mui/lab/LoadingButton'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { FormCheckboxField, FormPasswordField,FormTextField } from 'components/form/form-field'

import SocialLogin from '../components/social-login'

import { useRegister } from './register.hook'

export default function RegisterPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    loading,
    setCaptcha,
    registerDone,
    captcha,
    watch
  } = useRegister()

  const terms_accepted = watch('terms_accepted')

  if (registerDone) {
    return (
      <>
        Register done see email
      </>
    )
  }

  return (
    <>
      <Typography
        component={'h1'}
        variant={'h4'}>
          Sign up
      </Typography>
      <form
        onSubmit={handleSubmit}
        noValidate>
        <Stack
          gap={2}
          py={4}>
          <FormTextField
            errors={errors.email}
            label={'Email'}
            type={'email'}
            {...register('email', { required: true })}
          />

          <FormPasswordField
            errors={errors.password}
            label={'Password'}
            {...register('password', { required: true })}
          />

          <FormPasswordField
            errors={errors.confirm_password}
            label={'Confirm Password'}
            noMeter
            {...register('confirm_password', { required: true })}
          />

          {/* @ts-ignore */}
          <ReCAPTCHA
            onChange={setCaptcha}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
          />
          <FormCheckboxField
            errors={errors.terms_accepted}
            label={'I agree to Terms and Privacy'}
            {...register('terms_accepted', { required: true })}
          />
        </Stack>
        <Stack>
          <LoadingButton
            loading={loading}
            type={'submit'}
            variant={'contained'}
            disabled={
              !terms_accepted || !captcha
            }>
            Register
          </LoadingButton>
        </Stack>
      </form>
      <Divider
        sx={{ py: 2 }}>
        <Typography
          variant={'body1'}>
          or
        </Typography>
      </Divider>
      <SocialLogin />
    </>
  )
}
