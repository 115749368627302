import React from 'react'
import { FieldError } from 'react-hook-form/dist/types/errors'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { SelectProps } from '@mui/material/Select'
import TextField from '@mui/material/TextField'

import isEmpty from 'utils/isEmpty'
import omit from 'utils/omit'

import { FormFieldType } from './form-field.type'
import FormFieldHelp from './form-field-help.components'

export type SelectOption = {
  title: string
  value: string
}

type FormSelectFieldProps = FormFieldType & SelectProps<SelectOption> & {
  options?: Array<SelectOption>
  errors?: FieldError
}

function FormSelectField({ errors, options, ...props }: FormSelectFieldProps, ref: React.ForwardedRef<HTMLInputElement>) {
  const hasErrors = !isEmpty(errors)

  return (
    <FormControl
      error={hasErrors}
    >
      <TextField
        ref={ref}
        error={hasErrors}
        {...omit(props, ['watch'])}
        select
      >
        {options?.map(({ title, value }) => (
          <MenuItem
            key={value}
            value={value}
          >
            {title}
          </MenuItem>
        ))}

      </TextField>
      <FormFieldHelp
        errors={errors}
        {...props} />
    </FormControl>
  )
}

export default React.forwardRef(FormSelectField)
