import { User } from '../types/user'

export default function formatUser(user: User | undefined) {
  if (!user) {
    return ''
  }
  if (user.name) {
    return user.name
  }
  if (user.email) {
    return user.email
  }
}
